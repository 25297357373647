import React, { useEffect, useState, useRef, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import { fetchDataSub } from "./redux/datasub/datasubActions";
import { fetchDataBase } from "./redux/database/databaseActions";
import { fetchDataPhase } from "./redux/dataphase/dataphaseActions";

import * as s from "./styles/globalStyles";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import CircleTextLogo from "./CircleTextLogo";
import { Canvas } from "@react-three/fiber";
import * as Loader from "react-loader-spinner";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const BackContainer = styled.div`
  display: block;
  background-image: url(/config/images/dn2.gif);
  position: fixed;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center 31vh;
  background-size: 500px;
  width: 100%;
  height: 100%;
  z-index: -100;
`;

export const StyledButtonFixed = styled.button`
  font-family: "futura-pt-bold", BIZUDPGothicB;
  color: #000;
  font-size: 1.4rem;
  font-weight: 800;
  border-radius: 10px;
  padding: 0.2rem 0.5rem;
  position: relative;
  overflow: hidden;
  background-color: rgba(181, 255, 20, 0.9);
  :active {
    padding: 0.3rem 0.6rem;
  }
  :hover {
    color: purple;
    opacity: 0.8;
  }
`;

export const StyledButton = styled.button`
  font-family: "futura-pt-bold", BIZUDPGothicB;
  color: #000;
  font-size: 1.4rem;
  font-weight: 800;
  border-radius: 10px;
  padding: 0.6rem 4rem;
  position: relative;
  overflow: hidden;
  background-color: rgba(181, 255, 20, 0.9);
  ${({ bgImage }) =>
    bgImage &&
    `
    color: rgba(0, 0, 0, 0);
    background-image: url(${bgImage});
    background-size: cover;
    background-position:center;
  `}

  :active {
    padding: 0.3rem 2rem;
  }
  :hover {
    ${({ bgImage }) =>
      bgImage &&
      `
    color: rgba(0, 0, 0, 0);
  `}
    opacity: 0.8;
  }
`;
export const StyledConnectButton = styled.button`
  font-family: AEB;
  color: #000;
  min-width: 170px;
  min-height: 51px;
  font-size: 3rem;
  font-weight: 800;
  border-radius: 100px;
  padding: 0.6rem 3rem;
  position: relative;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0);
  background-image: url("/config/images/connect.jpg");
  background-size: cover;
  /* animation: gradation 10.7s ease-in infinite; */
  :active {
    padding: 0.3rem 2rem;
    background-image: url("/config/images/connect.hover.jpg");
  }
  :hover {
    opacity: 0.9;
    /* background-image: url("/config/images/connect.hover.jpg"); */
  }
`;

export const StyledButtonOparator = styled.button`
  color: #fff;
  font-size: 0.8rem;
  display: inline;
  border-radius: 10px;
  padding: 0.6rem 2rem;
  position: inline-block;
  overflow: hidden;
  background-color: #ff0000;
`;

export const StyledButtonOparatorOff = styled.button`
  color: #fff;
  font-size: 0.8rem;
  display: inline;
  border-radius: 10px;
  padding: 0.6rem 2rem;
  position: inline-block;
  overflow: hidden;
  background-color: #333;
  /* background: linear-gradient(to right, #e66465, #9198e5, #4ed, yellow); */
  background-size: 700% 700%;
`;

export const StyledInput = styled.input`
  color: #333;
  font-size: 2rem;
  border-radius: 10px;
  padding: 0.1rem 0.1rem;
  width: 100%;
  position: relative;
  overflow: hidden;
`;

export const StyledButtonFree = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 20%;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledButtonvipwl = styled.button`
  padding: 10px;
  margin-left: 20px;
  border-radius: 50px;
  border: none;
  background-color: blue;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const MainContainer = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  background-color: rgba(51, 51, 51, 0);
  width: 100%;
  background-image: url("/config/images/main/exo1.png");
  background-size: cover;
  background-position: top;
  padding: 5rem;
  /* height: 100vh; */
  border-radius: 2px;
  /* box-shadow: 0px 5px 11px 2px rgba(181, 255, 20, 0.7); */
`;

export const OperationContainer = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  background-color: rgba(51, 51, 51, 0);
  width: 100%;
  background-size: cover;
  background-position: top;
  padding: 5rem;
  height: 10vh;
  border-radius: 2px;
  /* box-shadow: 0px 5px 11px 2px rgba(181, 255, 20, 0.7); */
`;

export const StyledIncDecButton = styled.button`
  font-family: "futura-pt-bold", BIZUDPGothicB;
  font-weight: 700;
  padding: 10px;
  border-radius: 100%;
  margin: 0 4rem;
  /* border: solid 1px #333; */
  border: none;
  background-color: rgba(255, 255, 255, 0);
  padding: 20px;
  font-weight: bold;
  font-size: 3rem;
  color: #fff;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  /* box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3); */
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;

  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 100%;
  height: 100px;
  @media (min-width: 767px) {
    width: 500px;
    height: 100px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(181, 255, 20, 0.7);
  /* border: 4px dashed var(--secondary); */
  /* background-color: var(--accent); */
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledButtonReload = styled.button`
  font-family: "futura-pt-bold", BIZUDPGothicB !important;
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: #333;
  padding: 10px;
  font-weight: bold;
  color: white;
  width: 100%;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  :hover {
    opacity: 0.8;
  }
`;
export const StyledImgThanks = styled.img`
  /* box-shadow: 0px 5px 11px 2px rgba(181, 255, 20, 0.7);
  border: 4px solid var(--secondary); */
  /* background-color: var(--accent);
  border-radius: 100%; */
  width: 200px;
  @media (min-width: 900px) {
    width: 300px;
  }
  @media (min-width: 1000px) {
    width: 500px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;
export const StyledLinkMini = styled.a`
  color: var(--secondary);
  font-size: 0.5rem;
  text-decoration: none;
`;

function Home() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const datasub = useSelector((state) => state.datasub);
  const database = useSelector((state) => state.database);
  const dataphase = useSelector((state) => state.dataphase);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click to mint your NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [mintAmountWL, setmintAmountWL] = useState(1);
  const [mintAmountAL0, setMintAmountAL0] = useState(1);
  const [mintAmountAL1, setMintAmountAL1] = useState(1);
  const [mintAmountAL2, setMintAmountAL2] = useState(1);
  const [mintAmountAL3, setMintAmountAL3] = useState(1);
  const [mintAmountAL4, setMintAmountAL4] = useState(1);
  const [mintAmountAL5, setMintAmountAL5] = useState(1);
  const [mintAmountAL6, setMintAmountAL6] = useState(1);
  const [mintAmountAL7, setMintAmountAL7] = useState(1);

  const [minted, setminted] = useState(0);
  const [mintedWL, setmintedWL] = useState(0);
  const [whitelistProof0, setwhitelistProof0] = useState("");
  const [whitelistValid0, setwhitelistValid0] = useState(false);
  const [whitelistRoot0, setwhitelistRoot0] = useState("");

  const [allowlistProof0, setAllowlistProof0] = useState("");
  const [allowlistValid0, setAllowlistValid0] = useState(false);
  const [allowlistProof1, setAllowlistProof1] = useState("");
  const [allowlistValid1, setAllowlistValid1] = useState(false);
  const [allowlistProof2, setAllowlistProof2] = useState("");
  const [allowlistValid2, setAllowlistValid2] = useState(false);
  const [allowlistProof3, setAllowlistProof3] = useState("");
  const [allowlistValid3, setAllowlistValid3] = useState(false);
  const [allowlistProof4, setAllowlistProof4] = useState("");
  const [allowlistValid4, setAllowlistValid4] = useState(false);
  const [allowlistProof5, setAllowlistProof5] = useState("");
  const [allowlistValid5, setAllowlistValid5] = useState(false);
  const [allowlistProof6, setAllowlistProof6] = useState("");
  const [allowlistValid6, setAllowlistValid6] = useState(false);
  const [allowlistProof7, setAllowlistProof7] = useState("");
  const [allowlistValid7, setAllowlistValid7] = useState(false);

  const [allowlistRoot0, setAllowlistRoot0] = useState("");
  const [allowlistRoot1, setAllowlistRoot1] = useState("");
  const [allowlistRoot2, setAllowlistRoot2] = useState("");
  const [allowlistRoot3, setAllowlistRoot3] = useState("");
  const [allowlistRoot4, setAllowlistRoot4] = useState("");
  const [allowlistRoot5, setAllowlistRoot5] = useState("");
  const [allowlistRoot6, setAllowlistRoot6] = useState("");
  const [allowlistRoot7, setAllowlistRoot7] = useState("");

  const [canMint0, setcanMint0] = useState(0);

  const [canMintAl0, setcanMintAl0] = useState(10);
  const [canMintAl1, setcanMintAl1] = useState(30);
  const [canMintAl2, setcanMintAl2] = useState(20);
  const [canMintAl3, setcanMintAl3] = useState(20);
  const [canMintAl4, setcanMintAl4] = useState(20);
  const [canMintAl5, setcanMintAl5] = useState(10);
  const [canMintAl6, setcanMintAl6] = useState(10);
  const [canMintAl7, setcanMintAl7] = useState(10);

  const [mintedAL0, setMintedAL0] = useState(0);
  const [mintedAL1, setMintedAL1] = useState(0);
  const [mintedAL2, setMintedAL2] = useState(0);
  const [mintedAL3, setMintedAL3] = useState(0);
  const [mintedAL4, setMintedAL4] = useState(0);
  const [mintedAL5, setMintedAL5] = useState(0);
  const [mintedAL6, setMintedAL6] = useState(0);
  const [mintedAL7, setMintedAL7] = useState(0);

  const [revealNum, setrevealNum] = useState("");

  const [muteCtr, setMuteCtr] = useState(true);
  const [playCtr, setPlayCtr] = useState(null);
  const [showScrollButton, setShowScrollButton] = useState(true);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  // const fetchDatas = (account) => {
  //   dispatch(fetchData(account));
  //   dispatch(fetchDataSub(account));
  // };

  const changeRevealNum = (event) => {
    const newVal = event.target.value;
    if (revealNum != event.target.value) {
      setrevealNum(newVal);
      setrevealNum((newVal) => {
        //初回クリック時のpre_countは1
        console.dir(`pass:${newVal}`);
        return newVal;
      });
    }
  };

  const getWhitelist = (_phaseId) => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      let address = blockchain.account.toLowerCase();
      let { MerkleTree } = require("merkletreejs");
      let keccak256 = require("keccak256");

      let whitelist = require("./data/wl" + _phaseId + ".json");

      let validNuns = whitelist.map((addr) => addr.slice(-64));
      let adresses = whitelist.map((addr) => addr.slice(0, -64));

      let index = adresses.indexOf(address);
      let validNun = validNuns[index];

      let mintNum = 0;
      if (validNun !== undefined) {
        // mintNum = validNun.slice(-1);
        mintNum = parseInt(validNun, 16);
      }

      let hashedAddresses = whitelist.map((addr) => keccak256(addr));
      let merkleTree = new MerkleTree(hashedAddresses, keccak256, {
        sortPairs: true,
      });
      let hashedAddress = keccak256(address + validNun);
      let proof = merkleTree.getHexProof(hashedAddress);
      let root = merkleTree.getHexRoot();

      blockchain.smartContract.methods
        .isWhitelisted(blockchain.account, _phaseId, mintNum, proof)
        .call()
        .then((receipt) => {
          switch (_phaseId) {
            case 0:
              setwhitelistProof0(proof);
              setwhitelistValid0(receipt);
              setcanMint0(mintNum);
              // if (canMint0 > 0) {
              //   setMintable0(canMint0 - mintedWL0);
              // }
              console.log("WL" + _phaseId + ":proof/" + whitelistProof0);
              break;
            default:
              console.log("not found PhaseID");
          }

          // console.log('viplistValid/'+viplistValid);
          dispatch(fetchData(blockchain.account));
        });
      // setwhitelistProof(proof);
      // setwhitelistValid(valid);
      // console.log("WL:wallet/" + blockchain.account);
      // console.log("WL:proof/" + proof);
      switch (_phaseId) {
        case 0:
          console.log("WL0" + _phaseId + ":proof/" + whitelistProof0);
          break;
        default:
          console.log("not found PhaseID");
      }
      setwhitelistRoot0(root);
      console.log("WL" + _phaseId + ":root/" + root);
      console.log(whitelistValid0);
    }
  };

  const getAllowlist = (_phaseId) => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      let address = blockchain.account.toLowerCase();
      let { MerkleTree } = require("merkletreejs");
      let keccak256 = require("keccak256");

      let allowlist = require("./data/al" + _phaseId + ".json");

      let hashedAddresses = allowlist.map((addr) => keccak256(addr));
      let merkleTree = new MerkleTree(hashedAddresses, keccak256, {
        sortPairs: true,
      });
      let hashedAddress = keccak256(address);
      let proof = merkleTree.getHexProof(hashedAddress);
      let root = merkleTree.getHexRoot();
      // console.log('VIP:address/'+address+':::mintNum/'+mintNum);
      // console.log('VIP:hasheBefore/'+address+validNun);
      // console.log('VIP:hashed/'+hashedAddress);

      blockchain.smartContract.methods
        .isAllowlisted(blockchain.account, _phaseId, proof)
        .call()
        .then((receipt) => {
          switch (_phaseId) {
            case 0:
              setAllowlistProof0(proof);
              setAllowlistValid0(receipt);
              console.log("al0:valid:" + [receipt]);
              // if (canMintAlo > 0) {
              //   setMintable0(canMintAl0 - mintedAL0);
              // }
              console.log("AL" + _phaseId + ":proof/" + allowlistProof0);
              break;
            case 1:
              console.log("al1:valid:" + [receipt]);
              setAllowlistProof1(proof);
              setAllowlistValid1(receipt);
              // if (canMintAl1 > 0) {
              //   setMintable1(canMintAl1 - mintedAL1);
              // }
              console.log("AL" + _phaseId + ":proof/" + allowlistProof1);
              break;
            case 2:
              setAllowlistProof2(proof);
              setAllowlistValid2(receipt);
              // if (canMintAl2 > 0) {
              //   setMintable2(canMintAl2 - mintedAL2);
              // }
              console.log("AL" + _phaseId + ":proof/" + allowlistProof2);
              break;
            case 3:
              setAllowlistProof3(proof);
              setAllowlistValid3(receipt);
              // if (canMintAl3 > 0) {
              //   setMintable3(canMintAl3 - mintedAL3);
              // }
              console.log("AL" + _phaseId + ":proof/" + allowlistProof3);
              break;
            case 4:
              setAllowlistProof4(proof);
              setAllowlistValid4(receipt);
              // if (canMintAl4 > 0) {
              //   setMintable4(canMintAl4 - mintedAL4);
              // }
              console.log("AL" + _phaseId + ":proof/" + allowlistProof4);
              break;
            case 5:
              setAllowlistProof5(proof);
              setAllowlistValid5(receipt);
              // if (canMintAl5 > 0) {
              //   setMintable5(canMintAl5 - mintedAL5);
              // }
              console.log("AL" + _phaseId + ":proof/" + allowlistProof5);
              break;
            case 6:
              setAllowlistProof6(proof);
              setAllowlistValid6(receipt);
              // if (canMintAl6 > 0) {
              //   setMintable6(canMintAl6 - mintedAL6);
              // }
              console.log("AL" + _phaseId + ":proof/" + allowlistProof6);
              break;
            case 7:
              setAllowlistProof7(proof);
              setAllowlistValid7(receipt);
              // if (canMintAl7 > 0) {
              //   setMintable7(canMintAl7 - mintedAL7);
              // }
              console.log("AL" + _phaseId + ":proof/" + allowlistProof7);
              break;
            default:
              console.log("not found PhaseID");
          }

          // console.log('viplistValid/'+viplistValid);
          dispatch(fetchData(blockchain.account));
        });
      // setAllowlistProof(proof);
      // setAllowlistValid(valid);
      // console.log("WL:wallet/" + blockchain.account);
      // console.log("WL:proof/" + proof);
      switch (_phaseId) {
        case 0:
          setAllowlistRoot0(root);
          console.log("AL0" + _phaseId + ":proof/" + allowlistProof0);
          break;
        case 1:
          setAllowlistRoot1(root);
          console.log("AL1" + _phaseId + ":proof/" + allowlistProof1);
          break;
        case 2:
          setAllowlistRoot2(root);
          console.log("AL2" + _phaseId + ":proof/" + allowlistProof2);
          break;
        case 3:
          setAllowlistRoot3(root);
          console.log("AL3" + _phaseId + ":proof/" + allowlistProof3);
          break;
        case 4:
          setAllowlistRoot4(root);
          console.log("AL4" + _phaseId + ":proof/" + allowlistProof4);
          break;
        case 5:
          setAllowlistRoot5(root);
          console.log("AL5" + _phaseId + ":proof/" + allowlistProof5);
          break;
        case 6:
          setAllowlistRoot6(root);
          console.log("AL6" + _phaseId + ":proof/" + allowlistProof6);
          break;
        case 7:
          setAllowlistRoot7(root);
          console.log("AL7" + _phaseId + ":proof/" + allowlistProof7);
          break;
        default:
          console.log("not found PhaseID");
      }
      console.log("AL" + _phaseId + ":root/" + root);
      // console.log(whitelistValid);
    }
  };

  // setPublicSaleEnable
  const setPublicSaleEnable = (flagBool) => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 2);
    console.log("Cost: ", 0);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .setPublicSaleEnable(flagBool)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        {
          flagBool
            ? setFeedback("正常にPBセール開始されました！")
            : setFeedback("正常にPBセール停止されました！");
        }
        setClaimingNft(false);
        checkMinted();
        // dispatch(fetchData(blockchain.account));
        dispatch(fetchDataSub(blockchain.account));
      });
  };

  const setALSaleEnable = (_phaseId, flagBool) => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 2);
    console.log("Cost: ", 0);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .setAllowlistSaleEnable(_phaseId, flagBool)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        {
          flagBool
            ? setFeedback("正常にAL" + _phaseId + "セール開始されました！")
            : setFeedback("正常にAL" + _phaseId + "セール停止されました！");
        }
        setClaimingNft(false);
        checkMinted();
        dispatch(fetchDataSub(blockchain.account));
        dispatch(fetchDataBase(blockchain.account));
      });
  };

  const setWLSaleEnable = (_phaseId, flagBool) => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 2);
    console.log("Cost: ", 0);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .setWhitelistSaleEnable(_phaseId, flagBool)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        // console.log(receipt);
        {
          flagBool
            ? setFeedback("正常にWLセール開始されました！")
            : setFeedback("正常にWLセール停止されました！");
        }
        setClaimingNft(false);
        checkMinted();
        // dispatch(fetchData(blockchain.account));
        dispatch(fetchDataSub(blockchain.account));
      });
  };

  const setReveal = () => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 2);
    console.log("Cost: ", 0);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .setReveal(revealNum)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          // `WOW, the ${CONFIG.NFT_NAME} is yours! go visit opensea.io to view it.`
          `正常にリビールされました！openseaでご確認ください。`
        );
        setClaimingNft(false);
        checkMinted();
        // dispatch(fetchData(blockchain.account));
        dispatch(fetchDataSub(blockchain.account));
      });
  };

  const setBaseUrl = () => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 1);
    console.log("Cost: ", 0);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`ベースURL設定します ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .setBaseURI(
        "https://arweave.net/k8WJF26qR0Vp1Ir-X1xInbOA_5Z8tR0sdSK2AazmcGc/"
      )
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          // `WOW, the ${CONFIG.NFT_NAME} is yours! go visit opensea.io to view it.`
          `正常にベースURL設定されました！`
        );
        setClaimingNft(false);
        checkMinted();
        // dispatch(fetchData(blockchain.account));
      });
  };

  const wlRegist = () => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 2);
    setFeedback(`SETMERKLE WL...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .setMerkleRootWlWithId(0, whitelistRoot0)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        // console.log(receipt);
        setFeedback("正常にWL登録しました！");
        setClaimingNft(false);
        checkMinted();
        // dispatch(fetchData(blockchain.account));
        dispatch(fetchDataSub(blockchain.account));
      });
  };

  const fcfsUp = () => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 2);
    setFeedback(`fcfsUpL...`);
    setClaimingNft(true);
    let _phaseId = Number(data.fcfsPhase) + 1;
    blockchain.smartContract.methods
      .setPhaseId(0, _phaseId)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        // console.log(receipt);
        setFeedback("正常にFCFSリセットしました。");
        setClaimingNft(false);
        // dispatch(fetchData(blockchain.account));
        dispatch(fetchData(blockchain.account));
      });
  };

  const alUp = (_alId) => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 2);
    let _phaseId = 0;
    switch (_alId) {
      case 1:
        _phaseId = dataphase.vvip1Phase + 1;
        break;
      case 2:
        _phaseId = dataphase.vvip2Phase + 1;
        break;
      case 3:
        _phaseId = dataphase.vip1Phase + 1;
        break;
      case 4:
        _phaseId = dataphase.vip2Phase + 1;
        break;
      case 5:
        _phaseId = dataphase.vip3Phase + 1;
        break;
      case 6:
        _phaseId = dataphase.vip4Phase + 1;
        break;
      case 7:
        _phaseId = dataphase.kakuteiPhase + 1;
        break;
      default:
        console.log("not found PhaseID");
    }
    setFeedback(`alUpL...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .setPhaseId(_alId, _phaseId)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        // console.log(receipt);
        setFeedback(`正常にAL${_alId}リセットしました。`);
        setClaimingNft(false);
        // dispatch(fetchData(blockchain.account));
        dispatch(fetchData(blockchain.account));
      });
  };

  const PublicUp = () => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 2);
    setFeedback(`Publicリセット中...`);
    setClaimingNft(true);
    let _phaseId = Number(data.nowPhasePs) + 1;
    blockchain.smartContract.methods
      .setNowPhasePs(_phaseId)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        // console.log(receipt);
        setFeedback("正常にPUBLICリセットしました。");
        setClaimingNft(false);
        // dispatch(fetchData(blockchain.account));
        dispatch(fetchData(blockchain.account));
      });
  };

  const alRegist = (_phaseId) => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 2);
    let alRoot;

    switch (_phaseId) {
      case 0:
        alRoot = allowlistRoot0;
        break;
      case 1:
        alRoot = allowlistRoot1;
        break;
      case 2:
        alRoot = allowlistRoot2;
        break;
      case 3:
        alRoot = allowlistRoot3;
        break;
      case 4:
        alRoot = allowlistRoot4;
        break;
      case 5:
        alRoot = allowlistRoot5;
        break;
      case 6:
        alRoot = allowlistRoot6;
        break;
      case 7:
        alRoot = allowlistRoot7;
        break;
      default:
        console.log("not found PhaseID");
    }
    setFeedback(`SETMERKLE AL${_phaseId}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .setMerkleRootAlWithId(_phaseId, alRoot)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        // console.log(receipt);
        setFeedback("正常にAL" + _phaseId + "登録しました！");
        setClaimingNft(false);
        checkMinted();
        // dispatch(fetchData(blockchain.account));
        dispatch(fetchDataSub(blockchain.account));
      });
  };

  const withdraw = () => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 1);
    console.log("Cost: ", 0);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`出金中 ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .withdraw()
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          // `WOW, the ${CONFIG.NFT_NAME} is yours! go visit opensea.io to view it.`
          `正常に出金されました！`
        );
        setClaimingNft(false);
        checkMinted();
        // dispatch(fetchData(blockchain.account));
        dispatch(fetchDataSub(blockchain.account));
      });
  };

  const checkMinted = () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      blockchain.smartContract.methods
        .psMinted(blockchain.account)
        .call()
        .then((receipt) => {
          setminted(receipt);
          dispatch(fetchData(blockchain.account));
          dispatch(fetchDataSub(blockchain.account));
        });
    }
  };

  const checkMintedWL = (_phaseId) => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
        .wlMinted(blockchain.account)
        .call()
        .then((receipt) => {
          switch (_phaseId) {
            case 0:
              setmintedWL(receipt);
              break;
            default:
              console.log("not found PhaseID");
          }

          dispatch(fetchData(blockchain.account));
        });
    }
  };

  const checkMintedAL = (_phaseId) => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      blockchain.smartContract.methods
        .alIdMinted(_phaseId, blockchain.account)
        .call()
        .then((receipt) => {
          switch (_phaseId) {
            case 0:
              setMintedAL0(receipt);
              break;
            case 1:
              setMintedAL1(receipt);
              break;
            case 2:
              setMintedAL2(receipt);
              break;
            case 3:
              setMintedAL3(receipt);
              break;
            case 4:
              setMintedAL4(receipt);
              break;
            case 5:
              setMintedAL5(receipt);
              break;
            case 6:
              setMintedAL6(receipt);
              break;
            case 7:
              setMintedAL7(receipt);
              break;
            default:
              console.log("not found PhaseID");
          }

          dispatch(fetchData(blockchain.account));
          dispatch(fetchDataSub(blockchain.account));
        });
    }
  };

  const claimNFTsWl = async (_phaseId) => {
    let cost = CONFIG.WEI_COSTWL;
    // mintAmountWl;
    // console.log("amount::" + String(_mintAmountWl));
    let amount = mintAmountWL;
    let whitelistProof;
    let canMint;
    switch (_phaseId) {
      case 0:
        // amount = Number(mintAmountWl1);
        whitelistProof = whitelistProof0;
        canMint = canMint0;
      default:
        console.log("not found PhaseID");
    }

    // let amount = mintAmountWl;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * amount); //個数を１に固定0714(ふりっきー)
    let gasMulti = 1;
    // gasMulti = 0.7 * amount;
    // gasMulti += 1;
    let totalGasLimit = String(gasLimit * gasMulti); //個数を1に固定0714(ふりっきー)
    let method = await blockchain.smartContract.methods.whitelistMint(
      _phaseId,
      amount,
      canMint,
      whitelistProof
    );
    let gas = await method.estimateGas({
      from: blockchain.account,
      value: totalCostWei,
    });
    console.log("estimateGas: " + gas);
    let gasPrice = await blockchain.web3.eth.getGasPrice();
    gasPrice = Math.round(gasPrice * 1.2);
    console.log("gasPrice: " + gasPrice);

    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`${CONFIG.NFT_NAME} minting...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .whitelistMint(_phaseId, amount, canMint, whitelistProof)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
        gasPrice: gasPrice,
        gas: gas + 50000,
        // maxPriorityFeePerGas: "40000000000",
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("ERROR....もう一度お試しください");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback("minted.check on opensea.");
        setClaimingNft(false);
        checkMintedWL(_phaseId);
        // dispatch(fetchData(blockchain.account));
      });
  };

  const claimNFTsAl = (_phaseId) => {
    let cost = CONFIG.WEI_COSTAL0;
    let amount = 0;
    let allowlistProof = "";
    switch (_phaseId) {
      case 0:
        allowlistProof = allowlistProof0;
        amount = mintAmountAL0;
        cost = CONFIG.WEI_COSTAL0;
        break;
      case 1:
        allowlistProof = allowlistProof1;
        amount = mintAmountAL1;
        cost = CONFIG.WEI_COSTAL1;
        break;
      case 2:
        allowlistProof = allowlistProof2;
        amount = mintAmountAL2;
        cost = CONFIG.WEI_COSTAL2;
        break;
      case 3:
        allowlistProof = allowlistProof3;
        amount = mintAmountAL3;
        cost = CONFIG.WEI_COSTAL3;
        break;
      case 4:
        allowlistProof = allowlistProof4;
        amount = mintAmountAL4;
        cost = CONFIG.WEI_COSTAL4;
        break;
      case 5:
        allowlistProof = allowlistProof5;
        amount = mintAmountAL5;
        cost = CONFIG.WEI_COSTAL5;
        break;
      case 6:
        allowlistProof = allowlistProof6;
        amount = mintAmountAL6;
        cost = CONFIG.WEI_COSTAL6;
        break;
      case 7:
        allowlistProof = allowlistProof7;
        amount = mintAmountAL7;
        cost = CONFIG.WEI_COSTAL7;
        break;
      default:
        console.log("not found PhaseID");
    }
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * amount); //個数を１に固定0714(ふりっきー)
    let gasMulti = 1;
    // gasMulti = 0.7 * amount;
    // gasMulti += 1;
    let totalGasLimit = String(gasLimit * gasMulti); //個数を1に固定0714(ふりっきー)
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`${CONFIG.NFT_NAME} minting....`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .allowlistMint(_phaseId, amount, allowlistProof)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
        // maxPriorityFeePerGas: "40000000000",
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("ERROR....もう一度お試しください");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback("minted.check on opensea.");
        setClaimingNft(false);
        checkMintedAL(_phaseId);
        // dispatch(fetchData(blockchain.account));
      });
  };

  const claimNFTsPbMP = () => {
    let cost = CONFIG.WEI_COSTPB;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmountPBMP);
    // let totalGasLimit = String(gasLimit * mintAmountWL);
    let totalGasLimit = String(gasLimit * 1);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);

    blockchain.smartContract.methods
      .publicMintMP(mintAmountPBMP, publistProof)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("何かが間違っているようです。再度お試しください。");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          // `WOW, the ${CONFIG.NFT_NAME} is yours! go visit opensea.io to view it.`
          `minted.check on opensea.`
        );
        setClaimingNft(false);
        checkMinted();
        // dispatch(fetchData(blockchain.account));
        dispatch(fetchDataSub(blockchain.account));
      });
  };

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COSTPB;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    // let totalGasLimit = String(gasLimit * mintAmount);
    let totalGasLimit = String(gasLimit * 1);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .publicMint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          // `WOW, the ${CONFIG.NFT_NAME} is yours! go visit opensea.io to view it.`
          `minted.check on opensea.`
        );
        setClaimingNft(false);
        checkMinted();
        // dispatch(fetchData(blockchain.account));
        dispatch(fetchDataSub(blockchain.account));
        dispatch(fetchDataBase(blockchain.account));
      });
  };

  const appearAnim = (text) => {
    let textbox = "";
    text.split("").forEach((t, i) => {
      if (t !== " ") {
        if (i < 5) {
          textbox +=
            '<span style="animation-delay:.' + i + 's;">' + t + "</span>";
        } else {
          const n = (i + 10) / 10;
          if (i < 11) {
            textbox +=
              '<span style="animation-delay:' + n + 's;">' + t + "</span>";
          } else {
            textbox +=
              '<span style="animation-delay:' + n * 2 + 's;">' + t + "</span>";
          }
        }
      } else {
        textbox += t;
      }
    });
    return textbox;
  };

  const decrementMintAmount = (diffNum) => {
    let newMintAmount = mintAmount - diffNum;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = (diffNum) => {
    let newMintAmount = mintAmount + diffNum;
    if (newMintAmount > CONFIG.MAX_MINT) {
      newMintAmount = CONFIG.MAX_MINT;
    }
    setMintAmount(newMintAmount);
  };

  const decrementMintAmountPBMP = (diffNum) => {
    let newMintAmountPBMP = mintAmountPBMP - diffNum;
    if (newMintAmountPBMP < 1) {
      newMintAmountPBMP = 1;
    }
    setMintAmountPBMP(newMintAmountPBMP);
  };

  const incrementMintAmountPBMP = (diffNum) => {
    let newMintAmountPBMP = mintAmountPBMP + diffNum;
    if (newMintAmountPBMP > CONFIG.MAX_MINT) {
      newMintAmountPBMP = CONFIG.MAX_MINT;
    }
    setMintAmountPBMP(newMintAmountPBMP);
  };

  const decrementMintAmountWL = (diffNum) => {
    let newmintAmountWL = mintAmountWL - diffNum;
    if (newmintAmountWL < 1) {
      newmintAmountWL = 1;
    }
    setmintAmountWL(newmintAmountWL);
  };

  const incrementMintAmountWL = (diffNum) => {
    let newmintAmountWL = mintAmountWL + diffNum;
    let maxNum = canMint0 - mintedWL;
    if (newmintAmountWL > maxNum) {
      newmintAmountWL = maxNum;
    }
    setmintAmountWL(newmintAmountWL);
  };

  const changeMute = (_bool) => {
    setMuteCtr(_bool);
  };

  const getPlay = (_bool) => {
    setMuteCtr(!_bool);
    setPlayCtr(true);
  };

  const decrementMintAmountAL0 = (diffNum) => {
    let newMintAmountAL = mintAmountAL0 - diffNum;
    if (newMintAmountAL < 1) {
      newMintAmountAL = 1;
    }
    setMintAmountAL0(newMintAmountAL);
  };

  const incrementMintAmountAL0 = (diffNum) => {
    let newMintAmountAL = mintAmountAL0 + diffNum;
    let maxNum = canMintAl0 - mintedAL0;
    if (newMintAmountAL > maxNum) {
      newMintAmountAL = maxNum;
    }
    setMintAmountAL0(newMintAmountAL);
  };

  const decrementMintAmountAL1 = (diffNum) => {
    let newMintAmountAL = mintAmountAL1 - diffNum;
    if (newMintAmountAL < 1) {
      newMintAmountAL = 1;
    }
    setMintAmountAL1(newMintAmountAL);
  };

  const incrementMintAmountAL1 = (diffNum) => {
    let newMintAmountAL = mintAmountAL1 + diffNum;
    let maxNum = canMintAl1 - mintedAL1;
    if (newMintAmountAL > maxNum) {
      newMintAmountAL = maxNum;
    }
    setMintAmountAL1(newMintAmountAL);
  };

  const decrementMintAmountAL2 = (diffNum) => {
    let newMintAmountAL = mintAmountAL2 - diffNum;
    if (newMintAmountAL < 1) {
      newMintAmountAL = 1;
    }
    setMintAmountAL2(newMintAmountAL);
  };

  const incrementMintAmountAL2 = (diffNum) => {
    let newMintAmountAL = mintAmountAL2 + diffNum;
    let maxNum = canMintAl2 - mintedAL2;
    if (newMintAmountAL > maxNum) {
      newMintAmountAL = maxNum;
    }
    setMintAmountAL2(newMintAmountAL);
  };

  const decrementMintAmountAL3 = (diffNum) => {
    let newMintAmountAL = mintAmountAL3 - diffNum;
    if (newMintAmountAL < 1) {
      newMintAmountAL = 1;
    }
    setMintAmountAL3(newMintAmountAL);
  };

  const incrementMintAmountAL3 = (diffNum) => {
    let newMintAmountAL = mintAmountAL3 + diffNum;
    let maxNum = canMintAl3 - mintedAL3;
    if (newMintAmountAL > maxNum) {
      newMintAmountAL = maxNum;
    }
    setMintAmountAL3(newMintAmountAL);
  };

  const decrementMintAmountAL4 = (diffNum) => {
    let newMintAmountAL = mintAmountAL4 - diffNum;
    if (newMintAmountAL < 1) {
      newMintAmountAL = 1;
    }
    setMintAmountAL4(newMintAmountAL);
  };

  const incrementMintAmountAL4 = (diffNum) => {
    let newMintAmountAL = mintAmountAL4 + diffNum;
    let maxNum = canMintAl4 - mintedAL4;
    if (newMintAmountAL > maxNum) {
      newMintAmountAL = maxNum;
    }
    setMintAmountAL4(newMintAmountAL);
  };

  const decrementMintAmountAL5 = (diffNum) => {
    let newMintAmountAL = mintAmountAL5 - diffNum;
    if (newMintAmountAL < 1) {
      newMintAmountAL = 1;
    }
    setMintAmountAL5(newMintAmountAL);
  };

  const incrementMintAmountAL5 = (diffNum) => {
    let newMintAmountAL = mintAmountAL5 + diffNum;
    let maxNum = canMintAl5 - mintedAL5;
    if (newMintAmountAL > maxNum) {
      newMintAmountAL = maxNum;
    }
    setMintAmountAL5(newMintAmountAL);
  };

  const decrementMintAmountAL6 = (diffNum) => {
    let newMintAmountAL = mintAmountAL6 - diffNum;
    if (newMintAmountAL < 1) {
      newMintAmountAL = 1;
    }
    setMintAmountAL6(newMintAmountAL);
  };

  const incrementMintAmountAL6 = (diffNum) => {
    let newMintAmountAL = mintAmountAL6 + diffNum;
    let maxNum = canMintAl6 - mintedAL6;
    if (newMintAmountAL > maxNum) {
      newMintAmountAL = maxNum;
    }
    setMintAmountAL6(newMintAmountAL);
  };

  const decrementMintAmountAL7 = (diffNum) => {
    let newMintAmountAL = mintAmountAL7 - diffNum;
    if (newMintAmountAL < 1) {
      newMintAmountAL = 1;
    }
    setMintAmountAL7(newMintAmountAL);
  };

  const incrementMintAmountAL7 = (diffNum) => {
    let newMintAmountAL = mintAmountAL7 + diffNum;
    let maxNum = canMintAl7 - mintedAL7;
    if (newMintAmountAL > maxNum) {
      newMintAmountAL = maxNum;
    }
    setMintAmountAL7(newMintAmountAL);
  };

  const GoToMintPosition = () => {
    const mintBoxElement = document.getElementById("containerBox");

    if (mintBoxElement) {
      const rect = mintBoxElement.getBoundingClientRect();
      const distanceFromTop = rect.top + window.scrollY;
      window.scrollTo({
        top: distanceFromTop,
        behavior: "smooth",
      });
    } else {
      window.scrollTo({
        top: screen.height,
        behavior: "smooth",
      });
    }
  };
  const getData = async () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      dispatch(fetchData(blockchain.account));
      dispatch(fetchDataSub(blockchain.account));
      dispatch(fetchDataBase(blockchain.account));
      dispatch(fetchDataPhase(blockchain.account));

      let pendings = await blockchain.web3.eth.getTransactionCount(
        CONFIG.CONTRACT_ADDRESS,
        "pending"
      );
      console.dir("data:fcfsPhase:" + data.fcfsPhase);

      console.dir("pending:", pendings);
    }
  };

  const getInit = () => {
    if (blockchain.account === undefined) {
      setFeedback(`Click "Mint" to mint your NFT.`);
      setMintAmount(1);
      setminted(0);
    }
    getData();
    getWhitelist(0);
    getAllowlist(0);
    getAllowlist(1);
    getAllowlist(2);
    getAllowlist(3);
    getAllowlist(4);
    getAllowlist(5);
    getAllowlist(6);
    getAllowlist(7);
    checkMintedWL(0);
    checkMintedAL(0);
    checkMintedAL(1);
    checkMintedAL(2);
    checkMintedAL(3);
    checkMintedAL(4);
    checkMintedAL(5);
    checkMintedAL(6);
    checkMintedAL(7);
  };
  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  const getReload = () => {
    location.reload();
  };
  useEffect(() => {
    getConfig();
    getInit();
    let timeoutId = setTimeout(() => {
      // setMuteCtr(false);
    }, 500);
    let timeoutIdMute = setTimeout(() => {
      setMuteCtr(true);
      const mintBoxElement = document.getElementById("containerBox");

      if (mintBoxElement) {
        const rect = mintBoxElement.getBoundingClientRect();
        const distanceFromTop = rect.top + window.scrollY;
        window.scrollTo({
          top: distanceFromTop,
          behavior: "smooth",
        });
      } else {
        window.scrollTo({
          top: screen.height,
          behavior: "smooth",
        });
      }
    }, 30000);

    const eachTextAnimeControl = () => {
      const elements = document.querySelectorAll(".eachTextAnime");
      elements.forEach((element) => {
        const elemPos = element.getBoundingClientRect().top - 50;
        const scroll = window.scrollY;
        const windowHeight = window.innerHeight;
        if (scroll >= elemPos - windowHeight) {
          element.classList.add("appeartext");
        } else {
          element.classList.remove("appeartext");
        }
      });
    };
    // 画面が読み込まれたときの処理
    const handleLoad = () => {
      eachTextAnimeControl();
    };

    // 画面をスクロールしたときの処理
    const handleScroll = () => {
      eachTextAnimeControl();
      const scrollPercentage =
        (window.scrollY /
          (document.documentElement.scrollHeight - window.innerHeight)) *
        100;
      if (scrollPercentage >= 30) {
        setShowScrollButton(false);
      } else {
        setShowScrollButton(true);
      }
    };

    // イベントリスナーの登録
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("load", handleLoad);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("load", handleLoad);
      clearTimeout(timeoutId);
      clearTimeout(timeoutIdMute);
    };
  }, []);

  useEffect(() => {
    getInit();
  }, [blockchain.account]);

  useEffect(() => {
    setrevealNum(datasub.revealed);
  }, [datasub.revealed]);

  // useEffect(() => {
  //   let timeoutId = setTimeout(() => {
  //     setMuteCtr(false);
  //     setPlayCtr(true);
  //     window.scrollTo({
  //       top: 1,
  //       behavior: "smooth",
  //     });
  //   }, 500);
  //   return () => {
  //     clearTimeout(timeoutId);
  //   };
  // }, [playCtr]);

  // useEffect(() => {
  //   let timeoutId = setTimeout(() => {
  //     setMuteCtr(false);
  //   }, 500);
  //   let timeoutIdMute = setTimeout(() => {
  //     setMuteCtr(true);
  //     window.scrollTo({
  //       top: screen.height,
  //       behavior: "smooth",
  //     });
  //   }, 30000);
  //   return () => {
  //     clearTimeout(timeoutId);
  //     clearTimeout(timeoutIdMute);
  //   };
  // }, [muteCtr]);

  return (
    <s.Screen>
      {/* <div
        style={{
          position: "fixed",
          left: 0,
          zIndex: -1,
          width: "30vw",
          height: "100vh",
          backgroundImage: "url(/config/images/main/bg1.png)",
          backgroundSize: "cover",
          backgroundPosition: "left",
          backgroundRepeat: "no-repeat",
        }}
      ></div>
      <div
        style={{
          position: "fixed",
          right: 0,
          zIndex: -1,
          width: "30vw",
          height: "100vh",
          backgroundImage: "url(/config/images/main/bg2.png)",
          backgroundSize: "cover",
          backgroundPosition: "right",
          backgroundRepeat: "no-repeat",
        }}
      ></div> */}
      {/* {playCtr === null ? (
        <div style={{ height: "100vh", margin: "auto", display: "table" }}>
          <div style={{ display: "table-cell", verticalAlign: "middle" }}>
            <StyledButton
              style={{ display: "block" }}
              onClick={() => getPlay(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.0"
                width="1261.000000pt"
                height="1280.000000pt"
                viewBox="0 0 1261.000000 1280.000000"
                preserveAspectRatio="xMidYMid meet"
                style={{ width: 18, height: 18 }}
              >
                <g
                  transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
                  fill="#000000"
                  stroke="none"
                >
                  <path d="M6050 12785 c-253 -70 -587 -333 -1265 -995 -589 -576 -1509 -1531 -2360 -2450 -104 -113 -194 -208 -200 -212 -5 -4 -508 -8 -1117 -8 l-1108 0 0 -2725 0 -2725 1118 0 1117 -1 205 -226 c1253 -1386 2521 -2674 3048 -3098 527 -425 885 -455 996 -85 16 51 17 513 16 6093 0 5434 -2 6045 -16 6115 -42 208 -145 318 -307 328 -46 2 -92 -2 -127 -11z" />
                  <path d="M10392 12393 c-12 -2 -137 -117 -277 -255 -319 -313 -312 -296 -170 -451 155 -170 376 -478 537 -747 577 -968 947 -2149 1077 -3440 43 -420 46 -503 46 -1095 -1 -593 -5 -695 -51 -1120 -58 -547 -165 -1090 -318 -1605 -282 -954 -767 -1862 -1298 -2428 -72 -77 -78 -86 -78 -126 0 -34 6 -50 33 -78 47 -52 478 -432 506 -447 47 -26 89 -6 184 87 236 229 545 606 757 922 255 381 504 877 690 1375 504 1353 686 2967 519 4595 -91 886 -294 1742 -595 2503 -303 766 -637 1330 -1148 1942 -126 150 -292 332 -320 350 -26 17 -64 24 -94 18z" />
                  <path d="M8959 11043 c-24 -21 -150 -131 -280 -244 -196 -172 -237 -212 -243 -239 -9 -46 11 -78 124 -208 422 -484 801 -1238 1009 -2007 168 -625 242 -1208 242 -1915 0 -524 -37 -936 -127 -1405 -188 -974 -616 -1887 -1152 -2458 -80 -85 -92 -102 -92 -133 0 -19 7 -45 15 -58 15 -23 412 -400 488 -463 43 -36 81 -42 126 -19 76 40 474 488 663 747 509 698 860 1651 1002 2719 52 389 61 540 61 1065 0 485 -5 588 -41 915 -128 1155 -498 2205 -1057 3000 -186 265 -387 515 -539 673 -61 63 -68 67 -110 67 -37 0 -52 -6 -89 -37z" />
                  <path d="M7447 9826 c-28 -15 -464 -417 -544 -503 -36 -38 -43 -51 -43 -86 0 -39 5 -46 67 -102 375 -336 598 -626 803 -1040 249 -505 370 -1043 370 -1646 0 -1055 -398 -1980 -1108 -2576 -62 -53 -118 -106 -123 -119 -20 -53 8 -91 285 -387 291 -311 303 -321 371 -293 43 18 284 252 414 401 178 203 363 457 501 685 96 158 279 526 343 690 322 820 391 1698 201 2550 -185 824 -627 1622 -1244 2241 -190 191 -231 217 -293 185z" />
                </g>
              </svg>
              ON
            </StyledButton>
            <StyledButton
              style={{ display: "block" }}
              onClick={() => getPlay(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.0"
                width="1261.000000pt"
                height="1280.000000pt"
                viewBox="0 0 1261.000000 1280.000000"
                preserveAspectRatio="xMidYMid meet"
                style={{ width: 18, height: 18 }}
              >
                <g
                  transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
                  fill="#000000"
                  stroke="none"
                >
                  <path d="M6050 12785 c-253 -70 -587 -333 -1265 -995 -589 -576 -1509 -1531 -2360 -2450 -104 -113 -194 -208 -200 -212 -5 -4 -508 -8 -1117 -8 l-1108 0 0 -2725 0 -2725 1118 0 1117 -1 205 -226 c1253 -1386 2521 -2674 3048 -3098 527 -425 885 -455 996 -85 16 51 17 513 16 6093 0 5434 -2 6045 -16 6115 -42 208 -145 318 -307 328 -46 2 -92 -2 -127 -11z" />
                  <path d="M10392 12393 c-12 -2 -137 -117 -277 -255 -319 -313 -312 -296 -170 -451 155 -170 376 -478 537 -747 577 -968 947 -2149 1077 -3440 43 -420 46 -503 46 -1095 -1 -593 -5 -695 -51 -1120 -58 -547 -165 -1090 -318 -1605 -282 -954 -767 -1862 -1298 -2428 -72 -77 -78 -86 -78 -126 0 -34 6 -50 33 -78 47 -52 478 -432 506 -447 47 -26 89 -6 184 87 236 229 545 606 757 922 255 381 504 877 690 1375 504 1353 686 2967 519 4595 -91 886 -294 1742 -595 2503 -303 766 -637 1330 -1148 1942 -126 150 -292 332 -320 350 -26 17 -64 24 -94 18z" />
                  <path d="M8959 11043 c-24 -21 -150 -131 -280 -244 -196 -172 -237 -212 -243 -239 -9 -46 11 -78 124 -208 422 -484 801 -1238 1009 -2007 168 -625 242 -1208 242 -1915 0 -524 -37 -936 -127 -1405 -188 -974 -616 -1887 -1152 -2458 -80 -85 -92 -102 -92 -133 0 -19 7 -45 15 -58 15 -23 412 -400 488 -463 43 -36 81 -42 126 -19 76 40 474 488 663 747 509 698 860 1651 1002 2719 52 389 61 540 61 1065 0 485 -5 588 -41 915 -128 1155 -498 2205 -1057 3000 -186 265 -387 515 -539 673 -61 63 -68 67 -110 67 -37 0 -52 -6 -89 -37z" />
                  <path d="M7447 9826 c-28 -15 -464 -417 -544 -503 -36 -38 -43 -51 -43 -86 0 -39 5 -46 67 -102 375 -336 598 -626 803 -1040 249 -505 370 -1043 370 -1646 0 -1055 -398 -1980 -1108 -2576 -62 -53 -118 -106 -123 -119 -20 -53 8 -91 285 -387 291 -311 303 -321 371 -293 43 18 284 252 414 401 178 203 363 457 501 685 96 158 279 526 343 690 322 820 391 1698 201 2550 -185 824 -627 1622 -1244 2241 -190 191 -231 217 -293 185z" />
                </g>
              </svg>
              OFF
            </StyledButton>
          </div>
        </div>
      ) : (
        <> */}{" "}
      {blockchain.account === "" ||
      blockchain.smartContract === null ||
      blockchain.account === undefined ? (
        <></>
      ) : (
        <BackContainer />
      )}
      <div style={{ position: "fixed", right: 0, zIndex: 100 }}>
        {true && (
          <>
            {muteCtr ? (
              <StyledButtonFixed
                style={{ float: "right" }}
                onClick={() => changeMute(false)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.0"
                  width="1261.000000pt"
                  height="1280.000000pt"
                  viewBox="0 0 1261.000000 1280.000000"
                  preserveAspectRatio="xMidYMid meet"
                  style={{ width: 18, height: 18 }}
                >
                  <g
                    transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
                    fill="#ffffff"
                    stroke="none"
                  >
                    <path d="M6050 12785 c-253 -70 -587 -333 -1265 -995 -589 -576 -1509 -1531 -2360 -2450 -104 -113 -194 -208 -200 -212 -5 -4 -508 -8 -1117 -8 l-1108 0 0 -2725 0 -2725 1118 0 1117 -1 205 -226 c1253 -1386 2521 -2674 3048 -3098 527 -425 885 -455 996 -85 16 51 17 513 16 6093 0 5434 -2 6045 -16 6115 -42 208 -145 318 -307 328 -46 2 -92 -2 -127 -11z" />
                  </g>
                </svg>
              </StyledButtonFixed>
            ) : (
              <StyledButtonFixed
                style={{ float: "right" }}
                onClick={() => changeMute(true)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.0"
                  width="1261.000000pt"
                  height="1280.000000pt"
                  viewBox="0 0 1261.000000 1280.000000"
                  preserveAspectRatio="xMidYMid meet"
                  style={{ width: 18, height: 18 }}
                >
                  <g
                    transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
                    fill="#000000"
                    stroke="none"
                  >
                    <path d="M6050 12785 c-253 -70 -587 -333 -1265 -995 -589 -576 -1509 -1531 -2360 -2450 -104 -113 -194 -208 -200 -212 -5 -4 -508 -8 -1117 -8 l-1108 0 0 -2725 0 -2725 1118 0 1117 -1 205 -226 c1253 -1386 2521 -2674 3048 -3098 527 -425 885 -455 996 -85 16 51 17 513 16 6093 0 5434 -2 6045 -16 6115 -42 208 -145 318 -307 328 -46 2 -92 -2 -127 -11z" />
                    <path d="M10392 12393 c-12 -2 -137 -117 -277 -255 -319 -313 -312 -296 -170 -451 155 -170 376 -478 537 -747 577 -968 947 -2149 1077 -3440 43 -420 46 -503 46 -1095 -1 -593 -5 -695 -51 -1120 -58 -547 -165 -1090 -318 -1605 -282 -954 -767 -1862 -1298 -2428 -72 -77 -78 -86 -78 -126 0 -34 6 -50 33 -78 47 -52 478 -432 506 -447 47 -26 89 -6 184 87 236 229 545 606 757 922 255 381 504 877 690 1375 504 1353 686 2967 519 4595 -91 886 -294 1742 -595 2503 -303 766 -637 1330 -1148 1942 -126 150 -292 332 -320 350 -26 17 -64 24 -94 18z" />
                    <path d="M8959 11043 c-24 -21 -150 -131 -280 -244 -196 -172 -237 -212 -243 -239 -9 -46 11 -78 124 -208 422 -484 801 -1238 1009 -2007 168 -625 242 -1208 242 -1915 0 -524 -37 -936 -127 -1405 -188 -974 -616 -1887 -1152 -2458 -80 -85 -92 -102 -92 -133 0 -19 7 -45 15 -58 15 -23 412 -400 488 -463 43 -36 81 -42 126 -19 76 40 474 488 663 747 509 698 860 1651 1002 2719 52 389 61 540 61 1065 0 485 -5 588 -41 915 -128 1155 -498 2205 -1057 3000 -186 265 -387 515 -539 673 -61 63 -68 67 -110 67 -37 0 -52 -6 -89 -37z" />
                    <path d="M7447 9826 c-28 -15 -464 -417 -544 -503 -36 -38 -43 -51 -43 -86 0 -39 5 -46 67 -102 375 -336 598 -626 803 -1040 249 -505 370 -1043 370 -1646 0 -1055 -398 -1980 -1108 -2576 -62 -53 -118 -106 -123 -119 -20 -53 8 -91 285 -387 291 -311 303 -321 371 -293 43 18 284 252 414 401 178 203 363 457 501 685 96 158 279 526 343 690 322 820 391 1698 201 2550 -185 824 -627 1622 -1244 2241 -190 191 -231 217 -293 185z" />
                  </g>
                </svg>
              </StyledButtonFixed>
            )}
          </>
        )}

        {showScrollButton && (
          <StyledButtonFixed
            style={{ float: "right" }}
            onClick={() => GoToMintPosition()}
          >
            Mint
          </StyledButtonFixed>
        )}
      </div>
      <header id="header">
        <h1 className="" style={{ fontSize: "1.5rem" }}>
          <div
            className="eachTextAnime"
            dangerouslySetInnerHTML={{
              __html: appearAnim(" AEB BEGINS...."),
            }}
          />
        </h1>
        <div id="video-area">
          {muteCtr ? (
            <video
              id="video"
              poster=""
              webkitplaysinline="true"
              playsInline
              autoPlay
              muted
              loop
            >
              {isMobile ? (
                <source src="/config/images/main.mp4" type="video/mp4"></source>
              ) : (
                <source src="/config/images/main.mp4" type="video/mp4"></source>
              )}

              <source src="/config/images/main.ogv" type="video/ogv"></source>
              <source src="/config/images/main.webm" type="video/webm"></source>
              <p>動画を再生できる環境ではありません。</p>
            </video>
          ) : (
            <>
              <video
                id="video"
                poster=""
                webkitplaysinline="true"
                playsInline
                autoPlay
                loop
              >
                {isMobile ? (
                  <source
                    src="/config/images/main.mp4"
                    type="video/mp4"
                  ></source>
                ) : (
                  <source
                    src="/config/images/main.mp4"
                    type="video/mp4"
                  ></source>
                )}
                <source src="/config/images/main.ogv" type="video/ogv"></source>
                <source
                  src="/config/images/main.webm"
                  type="video/webm"
                ></source>
                <p>動画を再生できる環境ではありません。</p>
              </video>
            </>
          )}
        </div>
      </header>
      <s.Container
        flex={1}
        id="containerBox"
        ai={"center"}
        style={{
          padding: 0,
          backgroundColor: "rgba(0,0,0,0.6)",
          minHeight: "100vh",
        }}
        // image={CONFIG.SHOW_BACKGROUND ? "/config/images/main/bg.png" : null}
        // image={isMobile ? "/config/images/pcbg.png" : "/config/images/pcbg.png"}
        // image={isMobile ? null : null}
      >
        <ResponsiveWrapper
          flex={1}
          style={{ padding: "5rem 0" }}
          className={"kv"}
          test
        >
          {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
            <>
              <MainContainer
                flex={2}
                jc={"center"}
                ai={"center"}
                style={{ width: "100vw", overflowX: "hidden" }}
              >
                <span className="features">
                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      color: "var(--accent-text)",
                    }}
                  >
                    完売！！ありがとうございます！！今後ともよろしくお願いします。
                  </s.TextTitle>
                </span>
              </MainContainer>
            </>
          ) : (
            <>
              {blockchain.account === "" ||
              blockchain.smartContract === null ||
              blockchain.account === undefined ? (
                <MainContainer
                  flex={2}
                  jc={"center"}
                  ai={"center"}
                  style={{ width: "100vw", overflowX: "hidden" }}
                >
                  <span className="features">
                    <s.Container
                      ai={"center"}
                      jc={"center"}
                      style={{ height: "100vh" }}
                    >
                      {/* <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      Connect to the {CONFIG.NETWORK.NAME} network
                    </s.TextDescription> */}
                      {/* <CircleTextLogo /> */}
                      <s.SpacerSmall />

                      <StyledConnectButton
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(connect());
                          getData();
                          GoToMintPosition();
                        }}
                      ></StyledConnectButton>
                      {blockchain.errorMsg !== "" ? (
                        <>
                          <s.SpacerSmall />
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            {blockchain.errorMsg}
                          </s.TextDescription>
                        </>
                      ) : null}
                    </s.Container>
                  </span>
                </MainContainer>
              ) : (
                <>
                  <s.Container flex={1} jc={"center"} ai={"center"}>
                    <MainContainer
                      flex={2}
                      jc={"center"}
                      ai={"center"}
                      style={{
                        width: "100vw",
                        backgroundColor: "rgba(0, 0, 0, 0)",
                        backgroundSize: "500px",
                        padding: 100,
                        color: "white",
                      }}
                    >
                      <span className="features">
                        <s.Container ai={"center"}>
                          <s.TextTitle
                            style={{
                              textAlign: "center",
                              color: "white",
                              fontSize: "2rem",
                            }}
                          >
                            AEB BEGINS
                          </s.TextTitle>
                          <s.SpacerLarge />
                          <s.SpacerLarge />

                          <s.TextTitle
                            style={{
                              textAlign: "center",
                              fontSize: 50,
                              fontWeight: "bold",
                              color: "var(--accent-text)",
                            }}
                          >
                            {/* {("00000" + data.totalSupply).slice(-5)} */}
                            {"?????"}
                          </s.TextTitle>
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--primary-text)",
                            }}
                          >
                            <StyledLink
                              target={"_blank"}
                              href={CONFIG.SCAN_LINK}
                            >
                              {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
                            </StyledLink>
                          </s.TextDescription>
                          <s.SpacerSmall />
                          <s.SpacerLarge />
                          <s.SpacerLarge />

                          <s.SpacerXSmall />
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            Gas fee is required.
                          </s.TextDescription>

                          <s.SpacerSmall />
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            {feedback}
                          </s.TextDescription>
                          <s.SpacerLarge />
                          <s.SpacerLarge />

                          {/* セール状況ここから */}
                          {/* セール状況ここから */}
                          {/* セール状況ここから */}
                          {/* セール状況ここから */}

                          {(whitelistValid0 && datasub.isWlEnabled) ||
                          (allowlistValid0 && database.isAlEnabled0) ||
                          (allowlistValid1 && database.isAlEnabled1) ||
                          (allowlistValid2 && database.isAlEnabled2) ||
                          (allowlistValid3 && database.isAlEnabled3) ||
                          (allowlistValid4 && datasub.isAlEnabled4) ||
                          (allowlistValid5 && datasub.isAlEnabled5) ||
                          (allowlistValid6 && datasub.isAlEnabled6) ||
                          (allowlistValid7 && datasub.isAlEnabled7) ||
                          data.isPublicSaleEnabled ? (
                            <>
                              <s.TextTitle
                                style={{
                                  textAlign: "center",
                                  fontSize: 18,
                                  fontWeight: "bold",
                                  color: "var(--accent-text)",
                                }}
                              >
                                {whitelistValid0 && datasub.isWlEnabled
                                  ? "<FREE MINT>　"
                                  : ""}
                                {allowlistValid0 && database.isAlEnabled0
                                  ? "<FCFS>　"
                                  : ""}
                                {allowlistValid1 && database.isAlEnabled1
                                  ? "<VVIP1>　"
                                  : ""}
                                {allowlistValid2 && database.isAlEnabled2
                                  ? "<VVIP2>　"
                                  : ""}
                                {allowlistValid3 && database.isAlEnabled3
                                  ? "<VIP1>　"
                                  : ""}
                                {allowlistValid4 && datasub.isAlEnabled4
                                  ? "<VIP2>　"
                                  : ""}
                                {allowlistValid5 && datasub.isAlEnabled5
                                  ? "<VIP3>　"
                                  : ""}
                                {allowlistValid6 && datasub.isAlEnabled6
                                  ? "<VIP4>　"
                                  : ""}
                                {allowlistValid7 && datasub.isAlEnabled7
                                  ? "<GUARANTEED>　"
                                  : ""}
                                {data.isPublicSaleEnabled ? "<PUBLIC>　" : ""}
                                <s.SpacerLarge />

                                {"Now On Sale!"}
                              </s.TextTitle>
                              {/* <s.SpacerLarge />
                              <s.SpacerLarge /> */}
                              <s.SpacerLarge />
                            </>
                          ) : (
                            <>
                              <s.TextTitle
                                style={{
                                  textAlign: "center",
                                  fontSize: 20,
                                  fontWeight: "bold",
                                  color: "var(--accent-text)",
                                }}
                              >
                                {"Wait For Sale..."}
                              </s.TextTitle>
                            </>
                          )}
                          {/* セール状況ここまで */}
                          {/* セール状況ここまで */}
                          {/* セール状況ここまで */}
                          {/* セール状況ここまで */}

                          {/* フリーミント所有者の方ここから */}
                          {/* フリーミント所有者の方ここから */}
                          {/* フリーミント所有者の方ここから */}
                          {/* フリーミント所有者の方ここから */}
                          {/* フリーミント所有者の方ここから */}
                          {whitelistValid0 ? (
                            <>
                              {datasub.isWlEnabled ? (
                                <>
                                  <s.SpacerMedium />
                                  <s.Container
                                    ai={"center"}
                                    jc={"center"}
                                    fd={"row"}
                                  >
                                    <s.TextDescription
                                      style={{
                                        textAlign: "center",
                                        color: "var(--accent-text)",
                                        fontSize: "1.4rem",
                                      }}
                                    >
                                      FREE
                                    </s.TextDescription>
                                  </s.Container>{" "}
                                  <s.SpacerLarge />
                                  {canMint0 <= mintedWL ? (
                                    <>
                                      <s.Container
                                        ai={"center"}
                                        jc={"center"}
                                        fd={"row"}
                                      >
                                        <s.TextDescription
                                          style={{
                                            textAlign: "center",
                                            color: "var(--accent-text)",
                                          }}
                                        >
                                          {"Free Minted."}
                                        </s.TextDescription>
                                      </s.Container>
                                    </>
                                  ) : (
                                    <>
                                      <s.Container
                                        ai={"center"}
                                        jc={"center"}
                                        fd={"row"}
                                      >
                                        {/* <StyledIncDecButton
                                          style={{ lineHeight: 0.4 }}
                                          disabled={claimingNft ? 1 : 0}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            decrementMintAmountWL(canMint0);
                                            getData();
                                          }}
                                        >
                                          -{canMint0}
                                        </StyledIncDecButton> */}
                                        <StyledIncDecButton
                                          style={{ lineHeight: 0.4 }}
                                          disabled={claimingNft ? 1 : 0}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            decrementMintAmountWL(1);
                                            getData();
                                          }}
                                        >
                                          ＜
                                        </StyledIncDecButton>
                                        <s.SpacerMedium />
                                        <s.TextDescription
                                          style={{
                                            textAlign: "center",
                                            color: "var(--accent-text)",
                                            padding: 20,
                                          }}
                                        >
                                          {mintAmountWL}
                                        </s.TextDescription>
                                        <s.SpacerMedium />
                                        <StyledIncDecButton
                                          disabled={claimingNft ? 1 : 0}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            incrementMintAmountWL(1);
                                            getData();
                                          }}
                                        >
                                          ＞
                                        </StyledIncDecButton>
                                        {/* <StyledIncDecButton
                                          disabled={claimingNft ? 1 : 0}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            incrementMintAmountWL(canMint0);
                                            getData();
                                          }}
                                        >
                                          +{canMint0}
                                        </StyledIncDecButton> */}
                                      </s.Container>
                                      <s.SpacerLarge />

                                      <s.Container
                                        ai={"center"}
                                        jc={"center"}
                                        fd={"row"}
                                      >
                                        <StyledButton
                                          bgImage={
                                            !claimingNft &&
                                            "/config/images/als/AEB_99.png"
                                          }
                                          disabled={claimingNft ? 1 : 0}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            claimNFTsWl(0);
                                            getData();
                                          }}
                                        >
                                          {claimingNft
                                            ? "MINTING.."
                                            : mintAmountWL + " MINT"}
                                        </StyledButton>{" "}
                                      </s.Container>
                                      <s.SpacerLarge />

                                      <s.TextDescription
                                        style={{
                                          textAlign: "center",
                                          color: "var(--accent-text)",
                                        }}
                                      >
                                        {"("}
                                        {mintedWL}
                                        {"/"}
                                        {canMint0}
                                        {")"}
                                      </s.TextDescription>
                                      <s.SpacerLarge />
                                      <s.SpacerLarge />
                                      <s.SpacerLarge />
                                      <s.SpacerLarge />
                                      <s.SpacerLarge />
                                      <s.SpacerLarge />
                                    </>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                          {/* フリーミント所有者の方ここまで */}
                          {/* フリーミント所有者の方ここまで */}
                          {/* フリーミント所有者の方ここまで */}
                          {/* フリーミント所有者の方ここまで */}
                          {/* フリーミント所有者の方ここまで */}

                          {/* FCFS所有者の方ここから */}
                          {/* FCFS所有者の方ここから */}
                          {/* FCFS所有者の方ここから */}
                          {/* FCFS所有者の方ここから */}
                          {allowlistValid0 ? (
                            <>
                              {database.isAlEnabled0 ? (
                                <>
                                  <s.SpacerMedium />
                                  {canMintAl0 <= mintedAL0 ? (
                                    <>
                                      <s.Container
                                        ai={"center"}
                                        jc={"center"}
                                        fd={"row"}
                                      >
                                        <s.TextDescription
                                          style={{
                                            textAlign: "center",
                                            color: "var(--accent-text)",
                                          }}
                                        >
                                          {"FCFS Minted."}
                                        </s.TextDescription>
                                      </s.Container>
                                    </>
                                  ) : (
                                    <>
                                      <s.Container
                                        ai={"center"}
                                        jc={"center"}
                                        fd={"row"}
                                      >
                                        <s.TextDescription
                                          style={{
                                            textAlign: "center",
                                            color: "var(--accent-text)",
                                            fontSize: "1.4rem",
                                          }}
                                        >
                                          {Math.round(
                                            CONFIG.DISPLAY_COSTAL0 *
                                              mintAmountAL0 *
                                              1000
                                          ) / 1000}
                                          ETH
                                        </s.TextDescription>
                                      </s.Container>
                                      <s.SpacerLarge />

                                      <s.Container
                                        ai={"center"}
                                        jc={"center"}
                                        fd={"row"}
                                      >
                                        {/* <StyledIncDecButton
                                          style={{ lineHeight: 0.4 }}
                                          disabled={claimingNft ? 1 : 0}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            decrementMintAmountAL0(canMintAl0);
                                            getData();
                                          }}
                                        >
                                          -{canMintAl0}
                                        </StyledIncDecButton> */}
                                        <StyledIncDecButton
                                          style={{ lineHeight: 0.4 }}
                                          disabled={claimingNft ? 1 : 0}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            decrementMintAmountAL0(1);
                                            getData();
                                          }}
                                        >
                                          ＜
                                        </StyledIncDecButton>
                                        <s.SpacerMedium />
                                        <s.TextDescription
                                          style={{
                                            textAlign: "center",
                                            color: "var(--accent-text)",
                                            padding: 20,
                                          }}
                                        >
                                          {mintAmountAL0}
                                        </s.TextDescription>
                                        <s.SpacerMedium />
                                        <StyledIncDecButton
                                          disabled={claimingNft ? 1 : 0}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            incrementMintAmountAL0(1);
                                            getData();
                                          }}
                                        >
                                          ＞
                                        </StyledIncDecButton>
                                        {/* <StyledIncDecButton
                                          disabled={claimingNft ? 1 : 0}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            incrementMintAmountAL0(canMintAl0);
                                            getData();
                                          }}
                                        >
                                          +{canMintAl0}
                                        </StyledIncDecButton> */}
                                      </s.Container>
                                      <s.SpacerLarge />
                                      <s.Container
                                        ai={"center"}
                                        jc={"center"}
                                        fd={"row"}
                                      >
                                        <StyledButton
                                          bgImage={
                                            !claimingNft &&
                                            "/config/images/als/AEB_00.png"
                                          }
                                          disabled={claimingNft ? 1 : 0}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            claimNFTsAl(0);
                                            getData();
                                          }}
                                        >
                                          {claimingNft
                                            ? "MINTING.."
                                            : mintAmountAL0 + " MINT"}
                                        </StyledButton>
                                      </s.Container>
                                      <s.SpacerLarge />
                                      <s.TextDescription
                                        style={{
                                          textAlign: "center",
                                          color: "var(--accent-text)",
                                        }}
                                      >
                                        {"("}
                                        {mintedAL0}
                                        {"/"}
                                        {canMintAl0}
                                        {")"}
                                      </s.TextDescription>
                                      <s.SpacerLarge />
                                      <s.SpacerLarge />
                                      <s.SpacerLarge />
                                      <s.SpacerLarge />
                                      <s.SpacerLarge />
                                    </>
                                  )}
                                </>
                              ) : (
                                <>
                                  <s.SpacerMedium />
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    {/* {"アローリストセールは開始していません"} */}
                                  </s.TextDescription>
                                </>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                          {/* FCFS所有者の方ここまで */}
                          {/* FCFS所有者の方ここまで */}
                          {/* FCFS所有者の方ここまで */}
                          {/* FCFS所有者の方ここまで */}

                          {/* VVIP1所有者の方ここから */}
                          {/* VVIP1所有者の方ここから */}
                          {/* VVIP1所有者の方ここから */}
                          {/* VVIP1所有者の方ここから */}
                          {allowlistValid1 ? (
                            <>
                              {database.isAlEnabled1 ? (
                                <>
                                  <s.SpacerMedium />
                                  {canMintAl1 <= mintedAL1 ? (
                                    <>
                                      <s.Container
                                        ai={"center"}
                                        jc={"center"}
                                        fd={"row"}
                                      >
                                        <s.TextDescription
                                          style={{
                                            textAlign: "center",
                                            color: "var(--accent-text)",
                                          }}
                                        >
                                          {"VVIP1 Minted."}
                                        </s.TextDescription>
                                      </s.Container>
                                    </>
                                  ) : (
                                    <>
                                      <s.Container
                                        ai={"center"}
                                        jc={"center"}
                                        fd={"row"}
                                      >
                                        <s.TextDescription
                                          style={{
                                            textAlign: "center",
                                            color: "var(--accent-text)",
                                            fontSize: "1.4rem",
                                          }}
                                        >
                                          {Math.round(
                                            CONFIG.DISPLAY_COSTAL1 *
                                              mintAmountAL1 *
                                              1000
                                          ) / 1000}
                                          ETH
                                        </s.TextDescription>
                                      </s.Container>
                                      <s.SpacerLarge />
                                      <s.Container
                                        ai={"center"}
                                        jc={"center"}
                                        fd={"row"}
                                      >
                                        {/* <StyledIncDecButton
                                          style={{ lineHeight: 0.4 }}
                                          disabled={claimingNft ? 1 : 0}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            decrementMintAmountAL1(canMintAl1);
                                            getData();
                                          }}
                                        >
                                          -{canMintAl1}
                                        </StyledIncDecButton> */}
                                        <StyledIncDecButton
                                          style={{ lineHeight: 0.4 }}
                                          disabled={claimingNft ? 1 : 0}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            decrementMintAmountAL1(1);
                                            getData();
                                          }}
                                        >
                                          ＜
                                        </StyledIncDecButton>
                                        <s.SpacerMedium />
                                        <s.TextDescription
                                          style={{
                                            textAlign: "center",
                                            color: "var(--accent-text)",
                                            padding: 20,
                                          }}
                                        >
                                          {mintAmountAL1}
                                        </s.TextDescription>
                                        <s.SpacerMedium />
                                        <StyledIncDecButton
                                          disabled={claimingNft ? 1 : 0}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            incrementMintAmountAL1(1);
                                            getData();
                                          }}
                                        >
                                          ＞
                                        </StyledIncDecButton>
                                        {/* <StyledIncDecButton
                                          disabled={claimingNft ? 1 : 0}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            incrementMintAmountAL1(canMintAl1);
                                            getData();
                                          }}
                                        >
                                          +{canMintAl1}
                                        </StyledIncDecButton> */}
                                      </s.Container>
                                      <s.SpacerLarge />
                                      <s.Container
                                        ai={"center"}
                                        jc={"center"}
                                        fd={"row"}
                                      >
                                        <StyledButton
                                          bgImage={
                                            !claimingNft &&
                                            "/config/images/als/AEB_01.png"
                                          }
                                          disabled={claimingNft ? 1 : 0}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            claimNFTsAl(1);
                                            getData();
                                          }}
                                        >
                                          {claimingNft
                                            ? "MINTING.."
                                            : mintAmountAL1 + " MINT"}
                                        </StyledButton>
                                      </s.Container>
                                      <s.SpacerLarge />
                                      <s.TextDescription
                                        style={{
                                          textAlign: "center",
                                          color: "var(--accent-text)",
                                        }}
                                      >
                                        {"("}
                                        {mintedAL1}
                                        {"/"}
                                        {canMintAl1}
                                        {")"}
                                      </s.TextDescription>
                                      <s.SpacerLarge />
                                      <s.SpacerLarge />
                                      <s.SpacerLarge />
                                      <s.SpacerLarge />
                                      <s.SpacerLarge />
                                    </>
                                  )}
                                </>
                              ) : (
                                <>
                                  <s.SpacerMedium />
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    {/* {"アローリストセールは開始していません"} */}
                                  </s.TextDescription>
                                </>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                          {/* VVIP1所有者の方ここまで */}
                          {/* VVIP1所有者の方ここまで */}
                          {/* VVIP1所有者の方ここまで */}
                          {/* VVIP1所有者の方ここまで */}

                          {/* VVIP2所有者の方ここから */}
                          {/* VVIP2所有者の方ここから */}
                          {/* VVIP2所有者の方ここから */}
                          {/* VVIP2所有者の方ここから */}
                          {allowlistValid2 ? (
                            <>
                              {database.isAlEnabled2 ? (
                                <>
                                  <s.SpacerMedium />
                                  {canMintAl2 <= mintedAL2 ? (
                                    <>
                                      <s.Container
                                        ai={"center"}
                                        jc={"center"}
                                        fd={"row"}
                                      >
                                        <s.TextDescription
                                          style={{
                                            textAlign: "center",
                                            color: "var(--accent-text)",
                                          }}
                                        >
                                          {"VVIP2 Minted."}
                                        </s.TextDescription>
                                      </s.Container>
                                    </>
                                  ) : (
                                    <>
                                      <s.Container
                                        ai={"center"}
                                        jc={"center"}
                                        fd={"row"}
                                      >
                                        <s.TextDescription
                                          style={{
                                            textAlign: "center",
                                            color: "var(--accent-text)",
                                            fontSize: "1.4rem",
                                          }}
                                        >
                                          {Math.round(
                                            CONFIG.DISPLAY_COSTAL2 *
                                              mintAmountAL2 *
                                              1000
                                          ) / 1000}
                                          ETH
                                        </s.TextDescription>
                                      </s.Container>
                                      <s.SpacerLarge />
                                      <s.Container
                                        ai={"center"}
                                        jc={"center"}
                                        fd={"row"}
                                      >
                                        {/* <StyledIncDecButton
                                          style={{ lineHeight: 0.4 }}
                                          disabled={claimingNft ? 1 : 0}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            decrementMintAmountAL2(canMintAl2);
                                            getData();
                                          }}
                                        >
                                          -{canMintAl2}
                                        </StyledIncDecButton> */}
                                        <StyledIncDecButton
                                          style={{ lineHeight: 0.4 }}
                                          disabled={claimingNft ? 1 : 0}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            decrementMintAmountAL2(1);
                                            getData();
                                          }}
                                        >
                                          ＜
                                        </StyledIncDecButton>
                                        <s.SpacerMedium />
                                        <s.TextDescription
                                          style={{
                                            textAlign: "center",
                                            color: "var(--accent-text)",
                                            padding: 20,
                                          }}
                                        >
                                          {mintAmountAL2}
                                        </s.TextDescription>
                                        <s.SpacerMedium />
                                        <StyledIncDecButton
                                          disabled={claimingNft ? 1 : 0}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            incrementMintAmountAL2(1);
                                            getData();
                                          }}
                                        >
                                          ＞
                                        </StyledIncDecButton>
                                        {/* <StyledIncDecButton
                                          disabled={claimingNft ? 1 : 0}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            incrementMintAmountAL2(canMintAl2);
                                            getData();
                                          }}
                                        >
                                          +{canMintAl2}
                                        </StyledIncDecButton> */}
                                      </s.Container>
                                      <s.SpacerLarge />
                                      <s.Container
                                        ai={"center"}
                                        jc={"center"}
                                        fd={"row"}
                                      >
                                        <StyledButton
                                          bgImage={
                                            !claimingNft &&
                                            "/config/images/als/AEB_02.png"
                                          }
                                          disabled={claimingNft ? 1 : 0}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            claimNFTsAl(2);
                                            getData();
                                          }}
                                        >
                                          {claimingNft
                                            ? "MINTING.."
                                            : mintAmountAL2 + " MINT"}
                                        </StyledButton>
                                      </s.Container>
                                      <s.SpacerLarge />
                                      <s.TextDescription
                                        style={{
                                          textAlign: "center",
                                          color: "var(--accent-text)",
                                        }}
                                      >
                                        {"("}
                                        {mintedAL2}
                                        {"/"}
                                        {canMintAl2}
                                        {")"}
                                      </s.TextDescription>
                                      <s.SpacerLarge />
                                      <s.SpacerLarge />
                                      <s.SpacerLarge />
                                      <s.SpacerLarge />
                                      <s.SpacerLarge />
                                    </>
                                  )}
                                </>
                              ) : (
                                <>
                                  <s.SpacerMedium />
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    {/* {"アローリストセールは開始していません"} */}
                                  </s.TextDescription>
                                </>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                          {/* VVIP2所有者の方ここまで */}
                          {/* VVIP2所有者の方ここまで */}
                          {/* VVIP2所有者の方ここまで */}
                          {/* VVIP2所有者の方ここまで */}

                          {/* VIP1所有者の方ここから */}
                          {/* VIP1所有者の方ここから */}
                          {/* VIP1所有者の方ここから */}
                          {/* VIP1所有者の方ここから */}
                          {allowlistValid3 ? (
                            <>
                              {database.isAlEnabled3 ? (
                                <>
                                  <s.SpacerMedium />
                                  {canMintAl3 <= mintedAL3 ? (
                                    <>
                                      <s.Container
                                        ai={"center"}
                                        jc={"center"}
                                        fd={"row"}
                                      >
                                        <s.TextDescription
                                          style={{
                                            textAlign: "center",
                                            color: "var(--accent-text)",
                                          }}
                                        >
                                          {"VIP1 Minted."}
                                        </s.TextDescription>
                                      </s.Container>
                                    </>
                                  ) : (
                                    <>
                                      <s.Container
                                        ai={"center"}
                                        jc={"center"}
                                        fd={"row"}
                                      >
                                        <s.TextDescription
                                          style={{
                                            textAlign: "center",
                                            color: "var(--accent-text)",
                                            fontSize: "1.4rem",
                                          }}
                                        >
                                          {Math.round(
                                            CONFIG.DISPLAY_COSTAL3 *
                                              mintAmountAL3 *
                                              1000
                                          ) / 1000}
                                          ETH
                                        </s.TextDescription>
                                      </s.Container>
                                      <s.SpacerLarge />
                                      <s.Container
                                        ai={"center"}
                                        jc={"center"}
                                        fd={"row"}
                                      >
                                        {/* <StyledIncDecButton
                                          style={{ lineHeight: 0.4 }}
                                          disabled={claimingNft ? 1 : 0}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            decrementMintAmountAL3(canMintAl3);
                                            getData();
                                          }}
                                        >
                                          -{canMintAl3}
                                        </StyledIncDecButton> */}
                                        <StyledIncDecButton
                                          style={{ lineHeight: 0.4 }}
                                          disabled={claimingNft ? 1 : 0}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            decrementMintAmountAL3(1);
                                            getData();
                                          }}
                                        >
                                          ＜
                                        </StyledIncDecButton>
                                        <s.SpacerMedium />
                                        <s.TextDescription
                                          style={{
                                            textAlign: "center",
                                            color: "var(--accent-text)",
                                            padding: 20,
                                          }}
                                        >
                                          {mintAmountAL3}
                                        </s.TextDescription>
                                        <s.SpacerMedium />
                                        <StyledIncDecButton
                                          disabled={claimingNft ? 1 : 0}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            incrementMintAmountAL3(1);
                                            getData();
                                          }}
                                        >
                                          ＞
                                        </StyledIncDecButton>
                                        {/* <StyledIncDecButton
                                          disabled={claimingNft ? 1 : 0}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            incrementMintAmountAL3(canMintAl3);
                                            getData();
                                          }}
                                        >
                                          +{canMintAl3}
                                        </StyledIncDecButton> */}
                                      </s.Container>
                                      <s.SpacerLarge />
                                      <s.Container
                                        ai={"center"}
                                        jc={"center"}
                                        fd={"row"}
                                      >
                                        <StyledButton
                                          bgImage={
                                            !claimingNft &&
                                            "/config/images/als/AEB_03.png"
                                          }
                                          disabled={claimingNft ? 1 : 0}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            claimNFTsAl(3);
                                            getData();
                                          }}
                                        >
                                          {claimingNft
                                            ? "MINTING.."
                                            : mintAmountAL3 + " MINT"}
                                        </StyledButton>
                                      </s.Container>
                                      <s.SpacerLarge />
                                      <s.TextDescription
                                        style={{
                                          textAlign: "center",
                                          color: "var(--accent-text)",
                                        }}
                                      >
                                        {"("}
                                        {mintedAL3}
                                        {"/"}
                                        {canMintAl3}
                                        {")"}
                                      </s.TextDescription>{" "}
                                      <s.SpacerLarge />
                                      <s.SpacerLarge />
                                      <s.SpacerLarge />
                                      <s.SpacerLarge />
                                      <s.SpacerLarge />
                                    </>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                          {/* VIP1所有者の方ここまで */}
                          {/* VIP1所有者の方ここまで */}
                          {/* VIP1所有者の方ここまで */}
                          {/* VIP1所有者の方ここまで */}

                          {/* VIP2所有者の方ここから */}
                          {/* VIP2所有者の方ここから */}
                          {/* VIP2所有者の方ここから */}
                          {/* VIP2所有者の方ここから */}
                          {allowlistValid4 ? (
                            <>
                              {datasub.isAlEnabled4 ? (
                                <>
                                  <s.SpacerMedium />
                                  {canMintAl4 <= mintedAL4 ? (
                                    <>
                                      <s.Container
                                        ai={"center"}
                                        jc={"center"}
                                        fd={"row"}
                                      >
                                        <s.TextDescription
                                          style={{
                                            textAlign: "center",
                                            color: "var(--accent-text)",
                                          }}
                                        >
                                          {"VIP2 Minted."}
                                        </s.TextDescription>
                                      </s.Container>
                                    </>
                                  ) : (
                                    <>
                                      <s.Container
                                        ai={"center"}
                                        jc={"center"}
                                        fd={"row"}
                                      >
                                        <s.TextDescription
                                          style={{
                                            textAlign: "center",
                                            color: "var(--accent-text)",
                                            fontSize: "1.4rem",
                                          }}
                                        >
                                          {Math.round(
                                            CONFIG.DISPLAY_COSTAL4 *
                                              mintAmountAL4 *
                                              1000
                                          ) / 1000}
                                          ETH
                                        </s.TextDescription>
                                      </s.Container>
                                      <s.SpacerLarge />
                                      <s.Container
                                        ai={"center"}
                                        jc={"center"}
                                        fd={"row"}
                                      >
                                        {/* <StyledIncDecButton
                                          style={{ lineHeight: 0.4 }}
                                          disabled={claimingNft ? 1 : 0}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            decrementMintAmountAL4(canMintAl4);
                                            getData();
                                          }}
                                        >
                                          -{canMintAl4}
                                        </StyledIncDecButton> */}
                                        <StyledIncDecButton
                                          style={{ lineHeight: 0.4 }}
                                          disabled={claimingNft ? 1 : 0}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            decrementMintAmountAL4(1);
                                            getData();
                                          }}
                                        >
                                          ＜
                                        </StyledIncDecButton>
                                        <s.SpacerMedium />
                                        <s.TextDescription
                                          style={{
                                            textAlign: "center",
                                            color: "var(--accent-text)",
                                            padding: 20,
                                          }}
                                        >
                                          {mintAmountAL4}
                                        </s.TextDescription>
                                        <s.SpacerMedium />
                                        <StyledIncDecButton
                                          disabled={claimingNft ? 1 : 0}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            incrementMintAmountAL4(1);
                                            getData();
                                          }}
                                        >
                                          ＞
                                        </StyledIncDecButton>
                                        {/* <StyledIncDecButton
                                          disabled={claimingNft ? 1 : 0}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            incrementMintAmountAL4(canMintAl4);
                                            getData();
                                          }}
                                        >
                                          +{canMintAl4}
                                        </StyledIncDecButton> */}
                                      </s.Container>
                                      <s.SpacerLarge />
                                      <s.Container
                                        ai={"center"}
                                        jc={"center"}
                                        fd={"row"}
                                      >
                                        <StyledButton
                                          bgImage={
                                            !claimingNft &&
                                            "/config/images/als/AEB_04.png"
                                          }
                                          disabled={claimingNft ? 1 : 0}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            claimNFTsAl(4);
                                            getData();
                                          }}
                                        >
                                          {claimingNft
                                            ? "MINTING.."
                                            : mintAmountAL4 + " MINT"}
                                        </StyledButton>
                                      </s.Container>
                                      <s.SpacerLarge />
                                      <s.TextDescription
                                        style={{
                                          textAlign: "center",
                                          color: "var(--accent-text)",
                                        }}
                                      >
                                        {"("}
                                        {mintedAL4}
                                        {"/"}
                                        {canMintAl4}
                                        {")"}
                                      </s.TextDescription>{" "}
                                      <s.SpacerLarge />
                                      <s.SpacerLarge />
                                      <s.SpacerLarge />
                                      <s.SpacerLarge />
                                      <s.SpacerLarge />
                                    </>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                          {/* VIP2所有者の方ここまで */}
                          {/* VIP2所有者の方ここまで */}
                          {/* VIP2所有者の方ここまで */}
                          {/* VIP2所有者の方ここまで */}

                          {/* VIP3所有者の方ここから */}
                          {/* VIP3所有者の方ここから */}
                          {/* VIP3所有者の方ここから */}
                          {/* VIP3所有者の方ここから */}
                          {allowlistValid5 ? (
                            <>
                              {datasub.isAlEnabled5 ? (
                                <>
                                  <s.SpacerMedium />
                                  {canMintAl5 <= mintedAL5 ? (
                                    <>
                                      <s.Container
                                        ai={"center"}
                                        jc={"center"}
                                        fd={"row"}
                                      >
                                        <s.TextDescription
                                          style={{
                                            textAlign: "center",
                                            color: "var(--accent-text)",
                                          }}
                                        >
                                          {"VIP3 Minted."}
                                        </s.TextDescription>
                                      </s.Container>
                                    </>
                                  ) : (
                                    <>
                                      <s.Container
                                        ai={"center"}
                                        jc={"center"}
                                        fd={"row"}
                                      >
                                        <s.TextDescription
                                          style={{
                                            textAlign: "center",
                                            color: "var(--accent-text)",
                                            fontSize: "1.4rem",
                                          }}
                                        >
                                          {Math.round(
                                            CONFIG.DISPLAY_COSTAL5 *
                                              mintAmountAL5 *
                                              1000
                                          ) / 1000}
                                          ETH
                                        </s.TextDescription>
                                      </s.Container>
                                      <s.SpacerLarge />
                                      <s.Container
                                        ai={"center"}
                                        jc={"center"}
                                        fd={"row"}
                                      >
                                        {/* <StyledIncDecButton
                                          style={{ lineHeight: 0.4 }}
                                          disabled={claimingNft ? 1 : 0}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            decrementMintAmountAL5(canMintAl5);
                                            getData();
                                          }}
                                        >
                                          -{canMintAl5}
                                        </StyledIncDecButton> */}
                                        <StyledIncDecButton
                                          style={{ lineHeight: 0.4 }}
                                          disabled={claimingNft ? 1 : 0}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            decrementMintAmountAL5(1);
                                            getData();
                                          }}
                                        >
                                          ＜
                                        </StyledIncDecButton>
                                        <s.SpacerMedium />
                                        <s.TextDescription
                                          style={{
                                            textAlign: "center",
                                            color: "var(--accent-text)",
                                            padding: 20,
                                          }}
                                        >
                                          {mintAmountAL5}
                                        </s.TextDescription>
                                        <s.SpacerMedium />
                                        <StyledIncDecButton
                                          disabled={claimingNft ? 1 : 0}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            incrementMintAmountAL5(1);
                                            getData();
                                          }}
                                        >
                                          ＞
                                        </StyledIncDecButton>
                                        {/* <StyledIncDecButton
                                          disabled={claimingNft ? 1 : 0}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            incrementMintAmountAL5(canMintAl5);
                                            getData();
                                          }}
                                        >
                                          +{canMintAl5}
                                        </StyledIncDecButton> */}
                                      </s.Container>
                                      <s.SpacerLarge />
                                      <s.Container
                                        ai={"center"}
                                        jc={"center"}
                                        fd={"row"}
                                      >
                                        <StyledButton
                                          bgImage={
                                            !claimingNft &&
                                            "/config/images/als/AEB_05.png"
                                          }
                                          disabled={claimingNft ? 1 : 0}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            claimNFTsAl(5);
                                            getData();
                                          }}
                                        >
                                          {claimingNft
                                            ? "MINTING.."
                                            : mintAmountAL5 + " MINT"}
                                        </StyledButton>
                                      </s.Container>
                                      <s.SpacerLarge />
                                      <s.TextDescription
                                        style={{
                                          textAlign: "center",
                                          color: "var(--accent-text)",
                                        }}
                                      >
                                        {"("}
                                        {mintedAL5}
                                        {"/"}
                                        {canMintAl5}
                                        {")"}
                                      </s.TextDescription>
                                      <s.SpacerLarge />
                                      <s.SpacerLarge />
                                      <s.SpacerLarge />
                                      <s.SpacerLarge />
                                      <s.SpacerLarge />
                                    </>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                          {/* VIP3所有者の方ここまで */}
                          {/* VIP3所有者の方ここまで */}
                          {/* VIP3所有者の方ここまで */}
                          {/* VIP3所有者の方ここまで */}

                          {/* VIP4所有者の方ここから */}
                          {/* VIP4所有者の方ここから */}
                          {/* VIP4所有者の方ここから */}
                          {/* VIP4所有者の方ここから */}
                          {allowlistValid6 ? (
                            <>
                              {datasub.isAlEnabled6 ? (
                                <>
                                  <s.SpacerMedium />
                                  {canMintAl6 <= mintedAL6 ? (
                                    <>
                                      <s.Container
                                        ai={"center"}
                                        jc={"center"}
                                        fd={"row"}
                                      >
                                        <s.TextDescription
                                          style={{
                                            textAlign: "center",
                                            color: "var(--accent-text)",
                                          }}
                                        >
                                          {"VIP4 Minted."}
                                        </s.TextDescription>
                                      </s.Container>
                                    </>
                                  ) : (
                                    <>
                                      <s.Container
                                        ai={"center"}
                                        jc={"center"}
                                        fd={"row"}
                                      >
                                        <s.TextDescription
                                          style={{
                                            textAlign: "center",
                                            color: "var(--accent-text)",
                                            fontSize: "1.4rem",
                                          }}
                                        >
                                          {Math.round(
                                            CONFIG.DISPLAY_COSTAL6 *
                                              mintAmountAL6 *
                                              1000
                                          ) / 1000}
                                          ETH
                                        </s.TextDescription>
                                      </s.Container>
                                      <s.SpacerLarge />
                                      <s.Container
                                        ai={"center"}
                                        jc={"center"}
                                        fd={"row"}
                                      >
                                        {/* <StyledIncDecButton
                                          style={{ lineHeight: 0.4 }}
                                          disabled={claimingNft ? 1 : 0}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            decrementMintAmountAL6(canMintAl6);
                                            getData();
                                          }}
                                        >
                                          -{canMintAl6}
                                        </StyledIncDecButton> */}
                                        <StyledIncDecButton
                                          style={{ lineHeight: 0.4 }}
                                          disabled={claimingNft ? 1 : 0}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            decrementMintAmountAL6(1);
                                            getData();
                                          }}
                                        >
                                          ＜
                                        </StyledIncDecButton>
                                        <s.SpacerMedium />
                                        <s.TextDescription
                                          style={{
                                            textAlign: "center",
                                            color: "var(--accent-text)",
                                            padding: 20,
                                          }}
                                        >
                                          {mintAmountAL6}
                                        </s.TextDescription>
                                        <s.SpacerMedium />
                                        <StyledIncDecButton
                                          disabled={claimingNft ? 1 : 0}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            incrementMintAmountAL6(1);
                                            getData();
                                          }}
                                        >
                                          ＞
                                        </StyledIncDecButton>
                                        {/* <StyledIncDecButton
                                          disabled={claimingNft ? 1 : 0}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            incrementMintAmountAL6(canMintAl6);
                                            getData();
                                          }}
                                        >
                                          +{canMintAl6}
                                        </StyledIncDecButton> */}
                                      </s.Container>
                                      <s.SpacerLarge />
                                      <s.Container
                                        ai={"center"}
                                        jc={"center"}
                                        fd={"row"}
                                      >
                                        <StyledButton
                                          bgImage={
                                            !claimingNft &&
                                            "/config/images/als/AEB_06.png"
                                          }
                                          disabled={claimingNft ? 1 : 0}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            claimNFTsAl(6);
                                            getData();
                                          }}
                                        >
                                          {claimingNft
                                            ? "MINTING.."
                                            : mintAmountAL6 + " MINT"}
                                        </StyledButton>
                                      </s.Container>
                                      <s.SpacerLarge />
                                      <s.TextDescription
                                        style={{
                                          textAlign: "center",
                                          color: "var(--accent-text)",
                                        }}
                                      >
                                        {"("}
                                        {mintedAL6}
                                        {"/"}
                                        {canMintAl6}
                                        {")"}
                                      </s.TextDescription>
                                      <s.SpacerLarge />
                                      <s.SpacerLarge />
                                      <s.SpacerLarge />
                                      <s.SpacerLarge />
                                      <s.SpacerLarge />
                                    </>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                          {/* VIP4所有者の方ここまで */}
                          {/* VIP4所有者の方ここまで */}
                          {/* VIP4所有者の方ここまで */}
                          {/* VIP4所有者の方ここまで */}

                          {/* 確定所有者の方ここから */}
                          {/* 確定所有者の方ここから */}
                          {/* 確定所有者の方ここから */}
                          {/* 確定所有者の方ここから */}
                          {allowlistValid7 ? (
                            <>
                              {datasub.isAlEnabled7 ? (
                                <>
                                  <s.SpacerMedium />
                                  {canMintAl7 <= mintedAL7 ? (
                                    <>
                                      <s.Container
                                        ai={"center"}
                                        jc={"center"}
                                        fd={"row"}
                                      >
                                        <s.TextDescription
                                          style={{
                                            textAlign: "center",
                                            color: "var(--accent-text)",
                                          }}
                                        >
                                          {"確定Minted."}
                                        </s.TextDescription>
                                      </s.Container>
                                    </>
                                  ) : (
                                    <>
                                      <s.Container
                                        ai={"center"}
                                        jc={"center"}
                                        fd={"row"}
                                      >
                                        <s.TextDescription
                                          style={{
                                            textAlign: "center",
                                            color: "var(--accent-text)",
                                            fontSize: "1.4rem",
                                          }}
                                        >
                                          {Math.round(
                                            CONFIG.DISPLAY_COSTAL7 *
                                              mintAmountAL7 *
                                              1000
                                          ) / 1000}
                                          ETH
                                        </s.TextDescription>
                                      </s.Container>
                                      <s.SpacerLarge />
                                      <s.Container
                                        ai={"center"}
                                        jc={"center"}
                                        fd={"row"}
                                      >
                                        {/* <StyledIncDecButton
                                          style={{ lineHeight: 0.4 }}
                                          disabled={claimingNft ? 1 : 0}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            decrementMintAmountAL7(canMintAl7);
                                            getData();
                                          }}
                                        >
                                          -{canMintAl7}
                                        </StyledIncDecButton> */}
                                        <StyledIncDecButton
                                          style={{ lineHeight: 0.4 }}
                                          disabled={claimingNft ? 1 : 0}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            decrementMintAmountAL7(1);
                                            getData();
                                          }}
                                        >
                                          ＜
                                        </StyledIncDecButton>
                                        <s.SpacerMedium />
                                        <s.TextDescription
                                          style={{
                                            textAlign: "center",
                                            color: "var(--accent-text)",
                                            padding: 20,
                                          }}
                                        >
                                          {mintAmountAL7}
                                        </s.TextDescription>
                                        <s.SpacerMedium />
                                        <StyledIncDecButton
                                          disabled={claimingNft ? 1 : 0}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            incrementMintAmountAL7(1);
                                            getData();
                                          }}
                                        >
                                          ＞
                                        </StyledIncDecButton>
                                        {/* <StyledIncDecButton
                                          disabled={claimingNft ? 1 : 0}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            incrementMintAmountAL7(canMintAl7);
                                            getData();
                                          }}
                                        >
                                          +{canMintAl7}
                                        </StyledIncDecButton> */}
                                      </s.Container>
                                      <s.SpacerLarge />
                                      <s.Container
                                        ai={"center"}
                                        jc={"center"}
                                        fd={"row"}
                                      >
                                        <StyledButton
                                          bgImage={
                                            !claimingNft &&
                                            "/config/images/als/AEB_07.png"
                                          }
                                          disabled={claimingNft ? 1 : 0}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            claimNFTsAl(7);
                                            getData();
                                          }}
                                        >
                                          {claimingNft
                                            ? "MINTING.."
                                            : mintAmountAL7 + " MINT"}
                                        </StyledButton>
                                      </s.Container>
                                      <s.SpacerLarge />
                                      <s.TextDescription
                                        style={{
                                          textAlign: "center",
                                          color: "var(--accent-text)",
                                        }}
                                      >
                                        {"("}
                                        {mintedAL7}
                                        {"/"}
                                        {canMintAl7}
                                        {")"}
                                      </s.TextDescription>
                                      <s.SpacerLarge />
                                      <s.SpacerLarge />
                                      <s.SpacerLarge />
                                      <s.SpacerLarge />
                                      <s.SpacerLarge />
                                    </>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                          {/* 確定所有者の方ここまで */}
                          {/* 確定所有者の方ここまで */}
                          {/* 確定所有者の方ここまで */}
                          {/* 確定所有者の方ここまで */}

                          {/* ここからパブリックセール */}
                          {/* 通常パブセ */}
                          {data.isPublicSaleEnabled ? (
                            <>
                              <s.TextDescription
                                style={{
                                  textAlign: "center",
                                  color: "var(--accent-text)",
                                }}
                              >
                                {/* {"パブリックセール("}
                                {minted}
                                {"mint)"} */}
                              </s.TextDescription>

                              {CONFIG.MAX_MINT == minted ? (
                                <>
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    {"Minted."}
                                  </s.TextDescription>
                                </>
                              ) : (
                                <>
                                  <s.Container
                                    ai={"center"}
                                    jc={"center"}
                                    fd={"row"}
                                  >
                                    <s.TextDescription
                                      style={{
                                        textAlign: "center",
                                        color: "var(--accent-text)",
                                        fontSize: "1.4rem",
                                      }}
                                    >
                                      {Math.round(
                                        CONFIG.DISPLAY_COST * mintAmount * 1000
                                      ) / 1000}
                                      ETH
                                    </s.TextDescription>
                                  </s.Container>
                                  <s.SpacerLarge />{" "}
                                  <s.Container
                                    ai={"center"}
                                    jc={"center"}
                                    fd={"row"}
                                  >
                                    <StyledIncDecButton
                                      style={{ lineHeight: 0.4 }}
                                      disabled={claimingNft ? 1 : 0}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        decrementMintAmount(1);
                                        getData();
                                      }}
                                    >
                                      ＜
                                    </StyledIncDecButton>
                                    <s.SpacerMedium />
                                    <s.TextDescription
                                      style={{
                                        textAlign: "center",
                                        color: "var(--accent-text)",
                                        padding: 20,
                                      }}
                                    >
                                      {mintAmount}
                                    </s.TextDescription>
                                    <s.SpacerMedium />
                                    <StyledIncDecButton
                                      disabled={claimingNft ? 1 : 0}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        incrementMintAmount(1);
                                        getData();
                                      }}
                                    >
                                      ＞
                                    </StyledIncDecButton>
                                  </s.Container>
                                  <s.SpacerLarge />
                                  <s.Container
                                    ai={"center"}
                                    jc={"center"}
                                    fd={"row"}
                                  >
                                    <StyledButton
                                      disabled={claimingNft ? 1 : 0}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        claimNFTs();
                                        getData();
                                      }}
                                    >
                                      {claimingNft
                                        ? "MINTING.."
                                        : mintAmount + " MINT"}
                                    </StyledButton>
                                  </s.Container>
                                  <s.SpacerLarge />
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    {`(${minted}/${CONFIG.MAX_MINT})`}
                                  </s.TextDescription>
                                  <s.SpacerLarge />
                                  <s.SpacerLarge />
                                  <s.SpacerLarge />
                                  <s.SpacerLarge />
                                  <s.SpacerLarge />
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {/* <s.TextDescription
                              style={{
                                textAlign: "center",
                                color: "var(--accent-text)",
                              }}
                            >
                              {"パブリックセールは開始していません"}
                            </s.TextDescription> */}
                            </>
                          )}
                          {/* 通常パブセここまで */}

                          {/* スケジュールここから */}
                          {/* スケジュールここから */}
                          {/* スケジュールここから */}
                          {/* スケジュールここから */}
                          {/* スケジュールここから */}

                          {whitelistValid0 && (
                            <>
                              {!datasub.isWlEnabled && (
                                <>
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    FREE 2023/08/08 pm9:00-2023/08/09 pm9:00
                                  </s.TextDescription>
                                </>
                              )}
                            </>
                          )}
                          {allowlistValid0 && (
                            <>
                              {!database.isAlEnabled0 && (
                                <>
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    FCFS 2023/08/06 TBD
                                  </s.TextDescription>
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    FCFS 2023/08/07 TBD
                                  </s.TextDescription>
                                </>
                              )}
                            </>
                          )}
                          {allowlistValid1 && (
                            <>
                              {!database.isAlEnabled1 && (
                                <>
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    VVIP1 2023/08/08 pm9:00-2023/08/09 pm9:00
                                  </s.TextDescription>
                                </>
                              )}
                            </>
                          )}
                          {allowlistValid2 && (
                            <>
                              {!database.isAlEnabled2 && (
                                <>
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    VVIP2 2023/08/08 pm9:00-2023/08/09 pm9:00
                                  </s.TextDescription>
                                </>
                              )}
                            </>
                          )}
                          {allowlistValid3 && (
                            <>
                              {!database.isAlEnabled3 && (
                                <>
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    VIP1 2023/08/08 pm9:00-2023/08/09 pm9:00
                                  </s.TextDescription>
                                </>
                              )}
                            </>
                          )}
                          {allowlistValid4 && (
                            <>
                              {!datasub.isAlEnabled4 && (
                                <>
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    VIP2 2023/08/08 pm9:00-2023/08/09 pm9:00
                                  </s.TextDescription>
                                </>
                              )}
                            </>
                          )}

                          {allowlistValid5 && (
                            <>
                              {!datasub.isAlEnabled5 && (
                                <>
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    VIP3 2023/08/05 am8:00-2023/08/06 am8:00
                                  </s.TextDescription>
                                </>
                              )}
                            </>
                          )}

                          {allowlistValid6 && (
                            <>
                              {!datasub.isAlEnabled6 && (
                                <>
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    VIP4 2023/08/05 am8:00-2023/08/06 am8:00
                                  </s.TextDescription>
                                </>
                              )}
                            </>
                          )}

                          {allowlistValid7 && (
                            <>
                              {!datasub.isAlEnabled7 && (
                                <>
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    GUARANTEED 2023/08/05 am8:00-2023/08/06
                                    am8:00
                                  </s.TextDescription>
                                </>
                              )}
                            </>
                          )}

                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            PUBLIC 2023/08/06 TBD
                          </s.TextDescription>
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            PUBLIC 2023/08/07 TBD
                          </s.TextDescription>

                          {/* スケジュールここまで */}
                          {/* スケジュールここまで */}
                          {/* スケジュールここまで */}
                          {/* スケジュールここまで */}
                          {/* スケジュールここまで */}

                          <s.SpacerMedium />
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            NFT Market Place
                          </s.TextDescription>
                          <s.SpacerSmall />
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            <StyledLink
                              target={"_blank"}
                              href={CONFIG.MARKETPLACE_LINK}
                            >
                              {CONFIG.MARKETPLACE}
                            </StyledLink>
                          </s.TextDescription>
                          <s.SpacerLarge />

                          <s.Container ai={"center"} jc={"center"} fd={"row"}>
                            <StyledButtonReload
                              onClick={(e) => {
                                e.preventDefault();
                                getReload();
                              }}
                            >
                              {claimingNft ? "Busy" : "RELOAD"}
                            </StyledButtonReload>
                          </s.Container>
                        </s.Container>

                        {/* オペレーションボタン */}
                        {/* オペレーションボタン */}
                        {/* オペレーションボタン */}
                        {/* オペレーションボタン */}
                        {/* オペレーションボタン */}
                        {/* オペレーションボタン */}
                        {/* オペレーションボタン */}
                        {blockchain.account === "" ||
                        blockchain.smartContract === null ||
                        blockchain.account === undefined ? (
                          <></>
                        ) : (
                          <>
                            <OperationContainer
                              flex={6}
                              jc={"center"}
                              ai={"center"}
                            >
                              {"0x637d25D0769f747B2742A04d249802dA85395970" ==
                                blockchain.account ||
                              "0x27d2210a381e7D5921Fbb4dF6Fa39fd875ceCB57" ==
                                blockchain.account ? (
                                <>
                                  <s.Container
                                    ai={"center"}
                                    jc={"center"}
                                    style={{
                                      position: "fixed",
                                      top: "1rem",
                                      left: "1rem",
                                      width: "10rem",
                                    }}
                                  >
                                    {!data.isPublicSaleEnabled ? (
                                      <StyledButtonOparator
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setPublicSaleEnable(true);
                                          getData();
                                        }}
                                      >
                                        {claimingNft ? "Busy" : "PB.Start"}
                                      </StyledButtonOparator>
                                    ) : (
                                      <>
                                        <StyledButtonOparatorOff
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setPublicSaleEnable(false);
                                            getData();
                                          }}
                                        >
                                          {claimingNft ? "Busy" : "PB.Stop"}
                                        </StyledButtonOparatorOff>
                                      </>
                                    )}
                                    {!datasub.isWlEnabled ? (
                                      <StyledButtonOparator
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setWLSaleEnable(0, true);
                                          getData();
                                        }}
                                      >
                                        {claimingNft ? "Busy" : "WL.Start"}
                                      </StyledButtonOparator>
                                    ) : (
                                      <>
                                        <StyledButtonOparatorOff
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setWLSaleEnable(0, false);
                                            getData();
                                          }}
                                        >
                                          {claimingNft ? "Busy" : "WL.Stop"}
                                        </StyledButtonOparatorOff>
                                      </>
                                    )}
                                    {!database.isAlEnabled0 ? (
                                      <StyledButtonOparator
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setALSaleEnable(0, true);
                                          getData();
                                        }}
                                      >
                                        {claimingNft ? "Busy" : "FCFS.Start"}
                                      </StyledButtonOparator>
                                    ) : (
                                      <>
                                        <StyledButtonOparatorOff
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setALSaleEnable(0, false);
                                            getData();
                                          }}
                                        >
                                          {claimingNft ? "Busy" : "FCFS.Stop"}
                                        </StyledButtonOparatorOff>
                                      </>
                                    )}
                                    {!database.isAlEnabled1 ? (
                                      <StyledButtonOparator
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setALSaleEnable(1, true);
                                          getData();
                                        }}
                                      >
                                        {claimingNft ? "Busy" : "VVIP1.Start"}
                                      </StyledButtonOparator>
                                    ) : (
                                      <>
                                        <StyledButtonOparatorOff
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setALSaleEnable(1, false);
                                            getData();
                                          }}
                                        >
                                          {claimingNft ? "Busy" : "VVIP1.Stop"}
                                        </StyledButtonOparatorOff>
                                      </>
                                    )}
                                    {!database.isAlEnabled2 ? (
                                      <StyledButtonOparator
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setALSaleEnable(2, true);
                                          getData();
                                        }}
                                      >
                                        {claimingNft ? "Busy" : "VVIP2.Start"}
                                      </StyledButtonOparator>
                                    ) : (
                                      <>
                                        <StyledButtonOparatorOff
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setALSaleEnable(2, false);
                                            getData();
                                          }}
                                        >
                                          {claimingNft ? "Busy" : "VVIP2.Stop"}
                                        </StyledButtonOparatorOff>
                                      </>
                                    )}
                                    {!database.isAlEnabled3 ? (
                                      <StyledButtonOparator
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setALSaleEnable(3, true);
                                          getData();
                                        }}
                                      >
                                        {claimingNft ? "Busy" : "VIP1.Start"}
                                      </StyledButtonOparator>
                                    ) : (
                                      <>
                                        <StyledButtonOparatorOff
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setALSaleEnable(3, false);
                                            getData();
                                          }}
                                        >
                                          {claimingNft ? "Busy" : "VIP1.Stop"}
                                        </StyledButtonOparatorOff>
                                      </>
                                    )}
                                    {!datasub.isAlEnabled4 ? (
                                      <StyledButtonOparator
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setALSaleEnable(4, true);
                                          getData();
                                        }}
                                      >
                                        {claimingNft ? "Busy" : "VIP2.Start"}
                                      </StyledButtonOparator>
                                    ) : (
                                      <>
                                        <StyledButtonOparatorOff
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setALSaleEnable(4, false);
                                            getData();
                                          }}
                                        >
                                          {claimingNft ? "Busy" : "VIP2.Stop"}
                                        </StyledButtonOparatorOff>
                                      </>
                                    )}
                                    {!datasub.isAlEnabled5 ? (
                                      <StyledButtonOparator
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setALSaleEnable(5, true);
                                          getData();
                                        }}
                                      >
                                        {claimingNft ? "Busy" : "VIP3.Start"}
                                      </StyledButtonOparator>
                                    ) : (
                                      <>
                                        <StyledButtonOparatorOff
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setALSaleEnable(5, false);
                                            getData();
                                          }}
                                        >
                                          {claimingNft ? "Busy" : "VIP3.Stop"}
                                        </StyledButtonOparatorOff>
                                      </>
                                    )}
                                    {!datasub.isAlEnabled6 ? (
                                      <StyledButtonOparator
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setALSaleEnable(6, true);
                                          getData();
                                        }}
                                      >
                                        {claimingNft ? "Busy" : "VIP4.Start"}
                                      </StyledButtonOparator>
                                    ) : (
                                      <>
                                        <StyledButtonOparatorOff
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setALSaleEnable(6, false);
                                            getData();
                                          }}
                                        >
                                          {claimingNft ? "Busy" : "VIP4.Stop"}
                                        </StyledButtonOparatorOff>
                                      </>
                                    )}
                                    {!datasub.isAlEnabled7 ? (
                                      <StyledButtonOparator
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setALSaleEnable(7, true);
                                          getData();
                                        }}
                                      >
                                        {claimingNft ? "Busy" : "確定.Start"}
                                      </StyledButtonOparator>
                                    ) : (
                                      <>
                                        <StyledButtonOparatorOff
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setALSaleEnable(7, false);
                                            getData();
                                          }}
                                        >
                                          {claimingNft ? "Busy" : "確定.Stop"}
                                        </StyledButtonOparatorOff>
                                      </>
                                    )}
                                    <StyledButtonOparator
                                      onClick={(e) => {
                                        e.preventDefault();
                                        withdraw();
                                        getData();
                                      }}
                                    >
                                      {claimingNft ? "Busy" : "WITHDRAW"}
                                    </StyledButtonOparator>

                                    {whitelistRoot0 != "" && (
                                      <StyledButtonOparator
                                        onClick={(e) => {
                                          e.preventDefault();
                                          wlRegist();
                                          getData();
                                        }}
                                      >
                                        {claimingNft ? "Busy" : "WL登録"}
                                      </StyledButtonOparator>
                                    )}

                                    {allowlistRoot0 != "" && (
                                      <StyledButtonOparator
                                        onClick={(e) => {
                                          e.preventDefault();
                                          alRegist(0);
                                          getData();
                                        }}
                                      >
                                        {claimingNft ? "Busy" : "FCFS登録"}
                                      </StyledButtonOparator>
                                    )}

                                    {allowlistRoot1 != "" && (
                                      <StyledButtonOparator
                                        onClick={(e) => {
                                          e.preventDefault();
                                          alRegist(1);
                                          getData();
                                        }}
                                      >
                                        {claimingNft ? "Busy" : "VVIP1登録"}
                                      </StyledButtonOparator>
                                    )}

                                    {allowlistRoot2 != "" && (
                                      <StyledButtonOparator
                                        onClick={(e) => {
                                          e.preventDefault();
                                          alRegist(2);
                                          getData();
                                        }}
                                      >
                                        {claimingNft ? "Busy" : "VVIP2登録"}
                                      </StyledButtonOparator>
                                    )}

                                    {allowlistRoot3 != "" && (
                                      <StyledButtonOparator
                                        onClick={(e) => {
                                          e.preventDefault();
                                          alRegist(3);
                                          getData();
                                        }}
                                      >
                                        {claimingNft ? "Busy" : "VIP1登録"}
                                      </StyledButtonOparator>
                                    )}

                                    {allowlistRoot4 != "" && (
                                      <StyledButtonOparator
                                        onClick={(e) => {
                                          e.preventDefault();
                                          alRegist(4);
                                          getData();
                                        }}
                                      >
                                        {claimingNft ? "Busy" : "VIP2登録"}
                                      </StyledButtonOparator>
                                    )}

                                    {allowlistRoot5 != "" && (
                                      <StyledButtonOparator
                                        onClick={(e) => {
                                          e.preventDefault();
                                          alRegist(5);
                                          getData();
                                        }}
                                      >
                                        {claimingNft ? "Busy" : "VIP3登録"}
                                      </StyledButtonOparator>
                                    )}

                                    {allowlistRoot6 != "" && (
                                      <StyledButtonOparator
                                        onClick={(e) => {
                                          e.preventDefault();
                                          alRegist(6);
                                          getData();
                                        }}
                                      >
                                        {claimingNft ? "Busy" : "VIP4登録"}
                                      </StyledButtonOparator>
                                    )}

                                    {allowlistRoot7 != "" && (
                                      <StyledButtonOparator
                                        onClick={(e) => {
                                          e.preventDefault();
                                          alRegist(7);
                                          getData();
                                        }}
                                      >
                                        {claimingNft
                                          ? "Busy"
                                          : "確定リスト登録"}
                                      </StyledButtonOparator>
                                    )}
                                  </s.Container>
                                  <s.Container
                                    ai={"center"}
                                    jc={"center"}
                                    style={{
                                      position: "fixed",
                                      top: "3rem",
                                      right: "1rem",
                                      width: "10rem",
                                    }}
                                  >
                                    {data.fcfsPhase != null ? (
                                      <StyledButtonOparator
                                        onClick={(e) => {
                                          e.preventDefault();
                                          fcfsUp();
                                          getData();
                                        }}
                                      >
                                        {claimingNft
                                          ? "Busy"
                                          : `fcfsPhaseUP(${data.fcfsPhase})`}
                                      </StyledButtonOparator>
                                    ) : (
                                      <></>
                                    )}
                                    {data.fcfsPhase != null ? (
                                      <StyledButtonOparator
                                        onClick={(e) => {
                                          e.preventDefault();
                                          PublicUp();
                                          getData();
                                        }}
                                      >
                                        {claimingNft
                                          ? "Busy"
                                          : `Publicリセット(${data.nowPhasePs})`}
                                      </StyledButtonOparator>
                                    ) : (
                                      <></>
                                    )}
                                    {dataphase.vvip1Phase != null ? (
                                      <StyledButtonOparator
                                        onClick={(e) => {
                                          e.preventDefault();
                                          alUp(1);
                                          getData();
                                        }}
                                      >
                                        {claimingNft
                                          ? "Busy"
                                          : `vvip1リセット(${dataphase.vvip1Phase})`}
                                      </StyledButtonOparator>
                                    ) : (
                                      <></>
                                    )}
                                    {dataphase.vvip2Phase != null ? (
                                      <StyledButtonOparator
                                        onClick={(e) => {
                                          e.preventDefault();
                                          alUp(2);
                                          getData();
                                        }}
                                      >
                                        {claimingNft
                                          ? "Busy"
                                          : `vvip2リセット(${dataphase.vvip2Phase})`}
                                      </StyledButtonOparator>
                                    ) : (
                                      <></>
                                    )}
                                    {dataphase.vip1Phase != null ? (
                                      <StyledButtonOparator
                                        onClick={(e) => {
                                          e.preventDefault();
                                          alUp(3);
                                          getData();
                                        }}
                                      >
                                        {claimingNft
                                          ? "Busy"
                                          : `vip1リセット(${dataphase.vip1Phase})`}
                                      </StyledButtonOparator>
                                    ) : (
                                      <></>
                                    )}
                                    {dataphase.vip2Phase != null ? (
                                      <StyledButtonOparator
                                        onClick={(e) => {
                                          e.preventDefault();
                                          alUp(4);
                                          getData();
                                        }}
                                      >
                                        {claimingNft
                                          ? "Busy"
                                          : `vip2リセット(${dataphase.vip2Phase})`}
                                      </StyledButtonOparator>
                                    ) : (
                                      <></>
                                    )}
                                    {dataphase.vip3Phase != null ? (
                                      <StyledButtonOparator
                                        onClick={(e) => {
                                          e.preventDefault();
                                          alUp(5);
                                          getData();
                                        }}
                                      >
                                        {claimingNft
                                          ? "Busy"
                                          : `vip3リセット(${dataphase.vip3Phase})`}
                                      </StyledButtonOparator>
                                    ) : (
                                      <></>
                                    )}
                                    {dataphase.vip4Phase != null ? (
                                      <StyledButtonOparator
                                        onClick={(e) => {
                                          e.preventDefault();
                                          alUp(6);
                                          getData();
                                        }}
                                      >
                                        {claimingNft
                                          ? "Busy"
                                          : `vip4リセット(${dataphase.vip4Phase})`}
                                      </StyledButtonOparator>
                                    ) : (
                                      <></>
                                    )}
                                    {dataphase.kakuteiPhase != null ? (
                                      <StyledButtonOparator
                                        onClick={(e) => {
                                          e.preventDefault();
                                          alUp(7);
                                          getData();
                                        }}
                                      >
                                        {claimingNft
                                          ? "Busy"
                                          : `確定リセット(${dataphase.kakuteiPhase})`}
                                      </StyledButtonOparator>
                                    ) : (
                                      <></>
                                    )}
                                    <StyledButtonOparator
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setBaseUrl();
                                        getData();
                                      }}
                                    >
                                      {claimingNft
                                        ? "Busy"
                                        : "リビールURLセット"}
                                    </StyledButtonOparator>
                                    {revealNum != false && (
                                      <>
                                        <StyledInput
                                          type="text"
                                          value={revealNum}
                                          onChange={(e) => {
                                            e.preventDefault();
                                            changeRevealNum(e);
                                          }}
                                        ></StyledInput>
                                        <StyledButtonOparator
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setReveal();
                                            getData();
                                          }}
                                        >
                                          {claimingNft
                                            ? "Busy"
                                            : `リビール制限(${revealNum})`}
                                        </StyledButtonOparator>
                                      </>
                                    )}
                                  </s.Container>
                                </>
                              ) : (
                                <></>
                              )}
                            </OperationContainer>
                          </>
                        )}
                        {/* オペレーションボタン */}
                        {/* オペレーションボタン */}
                        {/* オペレーションボタン */}
                        {/* オペレーションボタン */}
                        {/* オペレーションボタン */}
                        {/* オペレーションボタン */}
                        {/* オペレーションボタン */}
                        {/* オペレーションボタン */}
                        {/* オペレーションボタン */}
                        {/* オペレーションボタン */}
                      </span>
                    </MainContainer>
                  </s.Container>
                </>
              )}
            </>
          )}
        </ResponsiveWrapper>
      </s.Container>
      {/* </>
      )} */}
    </s.Screen>
  );
}

export default Home;
