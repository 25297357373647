import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import styled from "styled-components";
import EachTextAnime from "./EachTextAnime";
export const BoxList = styled.div`
  /* background-color: #000; */
  position: relative;
  bottom: 0;
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 20;
`;
const ImageCatalog = () => {
  const imagesPerPage = 60;
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSearchResults, setCurrentSearchResults] = useState([]);
  const [imageNames, setImageNames] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [isLoading, setisLoading] = useState(true);

  // Dummy image data (replace with actual image data)
  const dummyImageNames = [];

  useEffect(() => {
    // Fetch image names and update state (replace with actual image fetching logic)
    setImageNames(dummyImageNames);
    searchImages();
    fetchFileListFromGAS();
  }, []);
  useEffect(() => {
    // Perform the search when searchInput changes
    const searchKeywords = searchInput
      .split(" ")
      .filter((keyword) => keyword.trim() !== "");

    if (searchKeywords.length === 0) {
      // No keywords entered, display all images
      setCurrentSearchResults(imageNames);
    } else {
      // Filter images that match all keywords
      setCurrentSearchResults(
        imageNames.filter((imageName) =>
          searchKeywords.every((keyword) => imageName.includes(keyword))
        )
      );
    }

    setCurrentPage(1);
  }, [searchInput, imageNames]);
  // Display images for the current page
  const displayImages = (page) => {
    const startIndex = (page - 1) * imagesPerPage;
    const endIndex = startIndex + imagesPerPage;
    const pageImages = currentSearchResults.slice(startIndex, endIndex);
    return (
      <BoxList image={"/config/images/1.png"}>
        <Grid container direction="column">
          <Grid item container>
            {pageImages.map((imageName, index) => {
              const fileName = imageName
                .split("/")
                .pop()
                .replace(/\.[^/.]+$/, ""); // Extract filename without extension
              return (
                <Grid xs={4} sm={2}>
                  <div key={startIndex + index}>
                    <span>#{fileName}</span>
                    <img
                      src={imageName}
                      alt={`Image ${index + 1}`}
                      onClick={() => openForm(startIndex + index + 1)}
                      style={{
                        cursor: "pointer",
                        maxWidth: "100%",
                        height: "auto",
                      }}
                    />
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </BoxList>
    );
  };
  const isImageFile = (name) => {
    const imageExtensions = [".png", ".jpg", ".jpeg", ".gif"]; // 画像として扱う拡張子リスト
    const extension = name.toLowerCase().substr(name.lastIndexOf("."));
    return imageExtensions.includes(extension);
  };
  const generateFileUrls = (fileData) => {
    return fileData
      .filter((file) => isImageFile(file.name))
      .map(
        (file) =>
          `https://lh3.googleusercontent.com/d/${file.id}?fN=/${file.name}`
      );
  };
  // Open form with image index as parameter
  const openForm = (imageIndex) => {
    // Replace with code to open form using imageIndex as parameter
    alert("Form should be opened with image index: " + imageIndex);
  };

  const fetchFileListFromGAS = async () => {
    try {
      setisLoading(true);
      const url = "https://aebselect.moroga.workers.dev/"; // GASウェブアプリケーションのURLを指定
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }).finally(() => {
        // setisLoading(false);
      });

      if (!response.ok) {
        throw new Error("Failed to fetch file list from GAS.");
      }

      const data = await response.json();
      setImageNames(generateFileUrls(data));
      console.log("File list from GAS:", generateFileUrls(data));
      setisLoading(false);

      // 取得したデータを必要に応じて処理する
    } catch (error) {
      console.error("Error fetching file list from GAS:", error);
    }
  };
  const getTotalPages = () =>
    Math.ceil(currentSearchResults.length / imagesPerPage);

  // Search images by filename
  const searchImages = () => {
    const searchInput = document.getElementById("searchInput").value;
    const searchKeywords = searchInput
      .split(" ")
      .filter((keyword) => keyword.trim() !== "");

    if (searchKeywords.length === 0) {
      // No keywords entered, display all images
      setCurrentSearchResults(imageNames);
    } else {
      // Filter images that match all keywords
      setCurrentSearchResults(
        imageNames.filter(
          (imageName) =>
            console.dir(
              imageName
                .split("/")
                .pop()
                .replace(/\.[^/.]+$/, "")
            ),
          searchKeywords.every((keyword) =>
            imageName
              .split("/")
              .pop()
              .replace(/\.[^/.]+$/, "")
              .includes(keyword)
          )
        )
      );
    }

    setCurrentPage(1);
  };

  // Navigate to previous page
  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Navigate to next page
  const nextPage = () => {
    const totalPages = Math.ceil(currentSearchResults.length / imagesPerPage);
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  // Navigate to a specific page
  const goToPage = (page) => {
    if (page >= 1 && page <= getTotalPages()) {
      setCurrentPage(page);
    }
  };
  return (
    <>
      {" "}
      {isLoading && (
        <div
          className="cover"
          style={{
            position: "fixed",
            display: "table",
            backgroundColor: "rgba(0,0,0,0.95)",
            height: "100vh",
            width: "100vw",
            zIndex: "1000",
            textAlign: "center",
          }}
        >
          <div
            style={{
              display: "table-cell",
              verticalAlign: "middle",
              width: "min-content",
            }}
          >
            <EachTextAnime text={"Loading..."} />
          </div>
        </div>
      )}
      <div>
        <div>
          <input
            type="text"
            id="searchInput"
            placeholder="Search by filename"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
          <button onClick={searchImages}>Search</button>
        </div>
        <div>
          <button onClick={previousPage} disabled={currentPage === 1}>
            Previous
          </button>
          {[...Array(getTotalPages()).keys()].map((pageNumber) => (
            <button
              key={pageNumber}
              onClick={() => goToPage(pageNumber + 1)}
              style={{
                backgroundColor:
                  currentPage === pageNumber + 1 ? "gray" : "white",
                cursor:
                  currentPage === pageNumber + 1 ? "not-allowed" : "pointer",
              }}
            >
              {pageNumber + 1}
            </button>
          ))}
          <button onClick={nextPage} disabled={currentPage === getTotalPages()}>
            Next
          </button>
        </div>
        {displayImages(currentPage)}
        <div>
          <button onClick={previousPage} disabled={currentPage === 1}>
            Previous
          </button>
          {[...Array(getTotalPages()).keys()].map((pageNumber) => (
            <button
              key={pageNumber}
              onClick={() => goToPage(pageNumber + 1)}
              style={{
                backgroundColor:
                  currentPage === pageNumber + 1 ? "gray" : "white",
                cursor:
                  currentPage === pageNumber + 1 ? "not-allowed" : "pointer",
              }}
            >
              {pageNumber + 1}
            </button>
          ))}
          <button onClick={nextPage} disabled={currentPage === getTotalPages()}>
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default ImageCatalog;
