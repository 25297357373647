import React, { VFC } from "react";
import { css, keyframes } from "@emotion/css";
const CircleTextLogo: VFC = () => {
  const text = "DN-2    PASS   SPACE SHIP   AEB DAO";
  //   const text = process.env.REACT_APP_HELLO_WORLD;

  return (
    <div className={styles.circle}>
      <div className={styles.logo}></div>
      <div className={styles.text}>
        {text.split("").map((char, i) => (
          <span key={i} className={styles.char(i * 9)}>
            {char}
          </span>
        ))}
      </div>
    </div>
  );
};

const animations = {
  rotateText: keyframes`
    0% {
      transform: rotate(360deg);
    }
    100% {
      transform: rotate(0deg);
    }
  `,
};

const sizePx = 200;

const styles = {
  circle: css`
    position: relative;
    width: ${sizePx}px;
    height: ${sizePx}px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  logo: css`
    position: absolute;
    width: ${sizePx - 100}px;
    height: ${sizePx - 100}px;
    background-image: url("/config/images/ufo.gif");
    background-size: cover;
    border-radius: 20%;
  `,
  text: css`
    font-family: "futura-pt-bold", BIZUDPGothicB;
    font-weight: 700;
    font-style: normal;
    font-size: 1.3rem;
    color: #fcfafc;
    position: absolute;
    width: 100%;
    height: 100%;
    animation: ${animations.rotateText} 10s linear infinite;
  `,
  char: (rotateDeg: number) => css`
    position: absolute;
    left: 50%;
    transform: rotate(${rotateDeg}deg);
    transform-origin: 0 ${sizePx / 2}px;
  `,
};

export default CircleTextLogo;
