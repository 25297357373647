import React, { useEffect, useState, useRef, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import { fetchDataSub } from "./redux/datasub/datasubActions";
import { fetchDataBase } from "./redux/database/databaseActions";
import { fetchDataPhase } from "./redux/dataphase/dataphaseActions";

import * as s from "./styles/globalStyles";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import CircleTextLogo from "./CircleTextLogo";
import { Canvas } from "@react-three/fiber";
import * as Loader from "react-loader-spinner";
import ImageCatalog from "./components/ImageCatalog";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const BackContainer = styled.div`
  display: block;
  background-image: url(/config/images/dn2.gif);
  position: fixed;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center 31vh;
  background-size: 500px;
  width: 100%;
  height: 100%;
  z-index: -100;
`;

export const StyledButtonFixed = styled.button`
  font-family: "futura-pt-bold", BIZUDPGothicB;
  color: #000;
  font-size: 1.4rem;
  font-weight: 800;
  border-radius: 10px;
  padding: 0.2rem 0.5rem;
  position: relative;
  overflow: hidden;
  background-color: rgba(181, 255, 20, 0.9);
  :active {
    padding: 0.3rem 0.6rem;
  }
  :hover {
    color: purple;
    opacity: 0.8;
  }
`;

export const StyledButton = styled.button`
  font-family: "futura-pt-bold", BIZUDPGothicB;
  color: #000;
  font-size: 1.4rem;
  font-weight: 800;
  border-radius: 10px;
  padding: 0.6rem 4rem;
  position: relative;
  overflow: hidden;
  background-color: rgba(181, 255, 20, 0.9);
  ${({ bgImage }) =>
    bgImage &&
    `
    color: rgba(0, 0, 0, 0);
    background-image: url(${bgImage});
    background-size: cover;
    background-position:center;
  `}

  :active {
    padding: 0.3rem 2rem;
  }
  :hover {
    ${({ bgImage }) =>
      bgImage &&
      `
    color: rgba(0, 0, 0, 0);
  `}
    opacity: 0.8;
  }
`;
export const StyledConnectButton = styled.button`
  font-family: AEB;
  color: #000;
  min-width: 170px;
  min-height: 51px;
  font-size: 3rem;
  font-weight: 800;
  border-radius: 100px;
  padding: 0.6rem 3rem;
  position: relative;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0);
  background-image: url("/config/images/connect.jpg");
  background-size: cover;
  /* animation: gradation 10.7s ease-in infinite; */
  :active {
    padding: 0.3rem 2rem;
    background-image: url("/config/images/connect.hover.jpg");
  }
  :hover {
    opacity: 0.9;
    /* background-image: url("/config/images/connect.hover.jpg"); */
  }
`;

export const StyledButtonOparator = styled.button`
  color: #fff;
  font-size: 0.8rem;
  display: inline;
  border-radius: 10px;
  padding: 0.6rem 2rem;
  position: inline-block;
  overflow: hidden;
  background-color: #ff0000;
`;

export const StyledButtonOparatorOff = styled.button`
  color: #fff;
  font-size: 0.8rem;
  display: inline;
  border-radius: 10px;
  padding: 0.6rem 2rem;
  position: inline-block;
  overflow: hidden;
  background-color: #333;
  /* background: linear-gradient(to right, #e66465, #9198e5, #4ed, yellow); */
  background-size: 700% 700%;
`;

export const StyledInput = styled.input`
  color: #777;
  font-size: 2rem;
  border-radius: 50px;
  padding: 0.6rem 3rem;
  width: 50%;
  position: relative;
  overflow: hidden;
`;

export const StyledButtonFree = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 20%;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledButtonvipwl = styled.button`
  padding: 10px;
  margin-left: 20px;
  border-radius: 50px;
  border: none;
  background-color: blue;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const MainContainer = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  background-color: rgba(51, 51, 51, 0);
  width: 100%;
  background-image: url("/config/images/main/exo1.png");
  background-size: cover;
  background-position: top;
  padding: 5rem;
  /* height: 100vh; */
  border-radius: 2px;
  /* box-shadow: 0px 5px 11px 2px rgba(181, 255, 20, 0.7); */
`;

export const OperationContainer = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  background-color: rgba(51, 51, 51, 0);
  width: 100%;
  background-size: cover;
  background-position: top;
  padding: 5rem;
  height: 10vh;
  border-radius: 2px;
  /* box-shadow: 0px 5px 11px 2px rgba(181, 255, 20, 0.7); */
`;

export const StyledIncDecButton = styled.button`
  font-family: "futura-pt-bold", BIZUDPGothicB;
  font-weight: 700;
  padding: 10px;
  border-radius: 100%;
  margin: 0 4rem;
  /* border: solid 1px #333; */
  border: none;
  background-color: rgba(255, 255, 255, 0);
  padding: 20px;
  font-weight: bold;
  font-size: 3rem;
  color: #fff;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  /* box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3); */
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;

  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 100%;
  height: 100px;
  @media (min-width: 767px) {
    width: 500px;
    height: 100px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(181, 255, 20, 0.7);
  /* border: 4px dashed var(--secondary); */
  /* background-color: var(--accent); */
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledButtonReload = styled.button`
  font-family: "futura-pt-bold", BIZUDPGothicB !important;
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: #333;
  padding: 10px;
  font-weight: bold;
  color: white;
  width: 100%;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  :hover {
    opacity: 0.8;
  }
`;
export const StyledImgThanks = styled.img`
  /* box-shadow: 0px 5px 11px 2px rgba(181, 255, 20, 0.7);
  border: 4px solid var(--secondary); */
  /* background-color: var(--accent);
  border-radius: 100%; */
  width: 200px;
  @media (min-width: 900px) {
    width: 300px;
  }
  @media (min-width: 1000px) {
    width: 500px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;
export const StyledLinkMini = styled.a`
  color: var(--secondary);
  font-size: 0.5rem;
  text-decoration: none;
`;

function Catalog() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const datasub = useSelector((state) => state.datasub);
  const database = useSelector((state) => state.database);
  const dataphase = useSelector((state) => state.dataphase);
  const [feedback, setFeedback] = useState(`Click to mint your NFT.`);
  const [muteCtr, setMuteCtr] = useState(true);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const GoToMintPosition = () => {
    const mintBoxElement = document.getElementById("containerBox");

    if (mintBoxElement) {
      const rect = mintBoxElement.getBoundingClientRect();
      const distanceFromTop = rect.top + window.scrollY;
      window.scrollTo({
        top: distanceFromTop,
        behavior: "smooth",
      });
    } else {
      window.scrollTo({
        top: screen.height,
        behavior: "smooth",
      });
    }
  };
  const getData = async () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      dispatch(fetchData(blockchain.account));
      dispatch(fetchDataSub(blockchain.account));
      dispatch(fetchDataBase(blockchain.account));
      dispatch(fetchDataPhase(blockchain.account));
    }
  };
  const changeMute = (_bool) => {
    setMuteCtr(_bool);
  };

  const getInit = () => {
    if (blockchain.account === undefined) {
      setFeedback(`Click "Mint" to mint your NFT.`);
    }
    getData();
  };
  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
    getInit();
  }, []);

  useEffect(() => {
    getInit();
  }, [blockchain.account]);

  return (
    <s.Screen>
      {blockchain.account === "" ||
      blockchain.smartContract === null ||
      blockchain.account === undefined ? (
        <></>
      ) : (
        <BackContainer />
      )}
      <div style={{ position: "fixed", right: 0, zIndex: 100 }}>
        {true && (
          <>
            {muteCtr ? (
              <StyledButtonFixed
                style={{ float: "right" }}
                onClick={() => changeMute(false)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.0"
                  width="1261.000000pt"
                  height="1280.000000pt"
                  viewBox="0 0 1261.000000 1280.000000"
                  preserveAspectRatio="xMidYMid meet"
                  style={{ width: 18, height: 18 }}
                >
                  <g
                    transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
                    fill="#ffffff"
                    stroke="none"
                  >
                    <path d="M6050 12785 c-253 -70 -587 -333 -1265 -995 -589 -576 -1509 -1531 -2360 -2450 -104 -113 -194 -208 -200 -212 -5 -4 -508 -8 -1117 -8 l-1108 0 0 -2725 0 -2725 1118 0 1117 -1 205 -226 c1253 -1386 2521 -2674 3048 -3098 527 -425 885 -455 996 -85 16 51 17 513 16 6093 0 5434 -2 6045 -16 6115 -42 208 -145 318 -307 328 -46 2 -92 -2 -127 -11z" />
                  </g>
                </svg>
              </StyledButtonFixed>
            ) : (
              <StyledButtonFixed
                style={{ float: "right" }}
                onClick={() => changeMute(true)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.0"
                  width="1261.000000pt"
                  height="1280.000000pt"
                  viewBox="0 0 1261.000000 1280.000000"
                  preserveAspectRatio="xMidYMid meet"
                  style={{ width: 18, height: 18 }}
                >
                  <g
                    transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
                    fill="#000000"
                    stroke="none"
                  >
                    <path d="M6050 12785 c-253 -70 -587 -333 -1265 -995 -589 -576 -1509 -1531 -2360 -2450 -104 -113 -194 -208 -200 -212 -5 -4 -508 -8 -1117 -8 l-1108 0 0 -2725 0 -2725 1118 0 1117 -1 205 -226 c1253 -1386 2521 -2674 3048 -3098 527 -425 885 -455 996 -85 16 51 17 513 16 6093 0 5434 -2 6045 -16 6115 -42 208 -145 318 -307 328 -46 2 -92 -2 -127 -11z" />
                    <path d="M10392 12393 c-12 -2 -137 -117 -277 -255 -319 -313 -312 -296 -170 -451 155 -170 376 -478 537 -747 577 -968 947 -2149 1077 -3440 43 -420 46 -503 46 -1095 -1 -593 -5 -695 -51 -1120 -58 -547 -165 -1090 -318 -1605 -282 -954 -767 -1862 -1298 -2428 -72 -77 -78 -86 -78 -126 0 -34 6 -50 33 -78 47 -52 478 -432 506 -447 47 -26 89 -6 184 87 236 229 545 606 757 922 255 381 504 877 690 1375 504 1353 686 2967 519 4595 -91 886 -294 1742 -595 2503 -303 766 -637 1330 -1148 1942 -126 150 -292 332 -320 350 -26 17 -64 24 -94 18z" />
                    <path d="M8959 11043 c-24 -21 -150 -131 -280 -244 -196 -172 -237 -212 -243 -239 -9 -46 11 -78 124 -208 422 -484 801 -1238 1009 -2007 168 -625 242 -1208 242 -1915 0 -524 -37 -936 -127 -1405 -188 -974 -616 -1887 -1152 -2458 -80 -85 -92 -102 -92 -133 0 -19 7 -45 15 -58 15 -23 412 -400 488 -463 43 -36 81 -42 126 -19 76 40 474 488 663 747 509 698 860 1651 1002 2719 52 389 61 540 61 1065 0 485 -5 588 -41 915 -128 1155 -498 2205 -1057 3000 -186 265 -387 515 -539 673 -61 63 -68 67 -110 67 -37 0 -52 -6 -89 -37z" />
                    <path d="M7447 9826 c-28 -15 -464 -417 -544 -503 -36 -38 -43 -51 -43 -86 0 -39 5 -46 67 -102 375 -336 598 -626 803 -1040 249 -505 370 -1043 370 -1646 0 -1055 -398 -1980 -1108 -2576 -62 -53 -118 -106 -123 -119 -20 -53 8 -91 285 -387 291 -311 303 -321 371 -293 43 18 284 252 414 401 178 203 363 457 501 685 96 158 279 526 343 690 322 820 391 1698 201 2550 -185 824 -627 1622 -1244 2241 -190 191 -231 217 -293 185z" />
                  </g>
                </svg>
              </StyledButtonFixed>
            )}
          </>
        )}
      </div>
      <div id="video-area">
        {muteCtr ? (
          <video
            id="video"
            poster=""
            webkitplaysinline="true"
            playsInline
            autoPlay
            muted
            loop
          >
            {isMobile ? (
              <source src="/config/images/main.mp4" type="video/mp4"></source>
            ) : (
              <source src="/config/images/main.mp4" type="video/mp4"></source>
            )}

            <source src="/config/images/main.ogv" type="video/ogv"></source>
            <source src="/config/images/main.webm" type="video/webm"></source>
            <p>動画を再生できる環境ではありません。</p>
          </video>
        ) : (
          <>
            <video
              id="video"
              poster=""
              webkitplaysinline="true"
              playsInline
              autoPlay
              loop
            >
              {isMobile ? (
                <source src="/config/images/main.mp4" type="video/mp4"></source>
              ) : (
                <source src="/config/images/main.mp4" type="video/mp4"></source>
              )}
              <source src="/config/images/main.ogv" type="video/ogv"></source>
              <source src="/config/images/main.webm" type="video/webm"></source>
              <p>動画を再生できる環境ではありません。</p>
            </video>
          </>
        )}
      </div>
      <s.Container
        flex={1}
        id="containerBox"
        ai={"center"}
        style={{
          padding: 0,
          backgroundColor: "rgba(0,0,0,0.6)",
          minHeight: "100vh",
        }}
        // image={CONFIG.SHOW_BACKGROUND ? "/config/images/main/bg.png" : null}
        // image={isMobile ? "/config/images/pcbg.png" : "/config/images/pcbg.png"}
        // image={isMobile ? null : null}
      >
        <ResponsiveWrapper
          flex={1}
          style={{ padding: "5rem 0" }}
          className={"kv"}
          test
        >
          {blockchain.account === "" ||
          blockchain.smartContract === null ||
          blockchain.account === undefined ? (
            <MainContainer
              flex={2}
              jc={"center"}
              ai={"center"}
              style={{ width: "100vw", overflowX: "hidden" }}
            >
              <span className="features">
                <s.Container
                  ai={"center"}
                  jc={"center"}
                  style={{ height: "100vh" }}
                >
                  {/* <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      Connect to the {CONFIG.NETWORK.NAME} network
                    </s.TextDescription> */}
                  {/* <CircleTextLogo /> */}
                  <s.SpacerSmall />

                  <StyledConnectButton
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(connect());
                      getData();
                      GoToMintPosition();
                    }}
                  ></StyledConnectButton>
                  {blockchain.errorMsg !== "" ? (
                    <>
                      <s.SpacerSmall />
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        {blockchain.errorMsg}
                      </s.TextDescription>
                    </>
                  ) : null}
                </s.Container>
              </span>
            </MainContainer>
          ) : (
            <>
              <s.Container flex={1} jc={"center"} ai={"center"}>
                <MainContainer
                  flex={2}
                  jc={"center"}
                  ai={"center"}
                  style={{
                    width: "100vw",
                    backgroundColor: "rgba(0, 0, 0, 0)",
                    backgroundSize: "500px",
                    padding: 0,
                    color: "white",
                  }}
                >
                  <ImageCatalog />
                </MainContainer>
              </s.Container>
            </>
          )}
        </ResponsiveWrapper>
      </s.Container>
    </s.Screen>
  );
}

export default Catalog;
