const initialState = {
  loading: false,
  vvip1Phase: 0,
  vvip2Phase: 0,
  vip1Phase: 0,
  vip2Phase: 0,
  vip3Phase: 0,
  vip4Phase: 0,
  kakuteiPhase: 0,
  cost: 0,
  error: false,
  errorMsg: "",
};

const dataPhaseReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHECK_DATAPHASE_REQUEST":
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATAPHASE_SUCCESS":
      return {
        ...state,
        loading: false,
        vvip1Phase: action.payload.vvip1Phase,
        vvip2Phase: action.payload.vvip2Phase,
        vip1Phase: action.payload.vip1Phase,
        vip2Phase: action.payload.vip2Phase,
        vip3Phase: action.payload.vip3Phase,
        vip4Phase: action.payload.vip4Phase,
        kakuteiPhase: action.payload.kakuteiPhase,

        // cost: action.payload.cost,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATAPHASE_FAILED":
      return {
        ...initialState,
        loading: false,
        error: true,
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default dataPhaseReducer;
