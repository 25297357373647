// log
import store from "../store";

const fetchDataPhaseRequest = () => {
  return {
    type: "CHECK_DATAPHASE_REQUEST",
  };
};

const fetchDataPhaseSuccess = (payload) => {
  return {
    type: "CHECK_DATAPHASE_SUCCESS",
    payload: payload,
  };
};

const fetchDataPhaseFailed = (payload) => {
  return {
    type: "CHECK_DATAPHASE_FAILED",
    payload: payload,
  };
};

export const fetchDataPhase = () => {
  return async (dispatch) => {
    dispatch(fetchDataPhaseRequest());
    try {
      let vvip1Phase = await store
        .getState()
        .blockchain.smartContract.methods.getPhaseIds(1)
        .call();
      let vvip2Phase = await store
        .getState()
        .blockchain.smartContract.methods.getPhaseIds(2)
        .call();
      let vip1Phase = await store
        .getState()
        .blockchain.smartContract.methods.getPhaseIds(3)
        .call();
      let vip2Phase = await store
        .getState()
        .blockchain.smartContract.methods.getPhaseIds(4)
        .call();
      let vip3Phase = await store
        .getState()
        .blockchain.smartContract.methods.getPhaseIds(5)
        .call();
      let vip4Phase = await store
        .getState()
        .blockchain.smartContract.methods.getPhaseIds(6)
        .call();
      let kakuteiPhase = await store
        .getState()
        .blockchain.smartContract.methods.getPhaseIds(7)
        .call();

      dispatch(
        fetchDataPhaseSuccess({
          vvip1Phase,
          vvip2Phase,
          vip1Phase,
          vip2Phase,
          vip3Phase,
          vip4Phase,
          kakuteiPhase,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataPhaseFailed("Could not load data from contract."));
    }
  };
};
