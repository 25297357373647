// log
import store from "../store";

const fetchDataSubRequest = () => {
  return {
    type: "CHECK_DATASUB_REQUEST",
  };
};

const fetchDataSubSuccess = (payload) => {
  return {
    type: "CHECK_DATASUB_SUCCESS",
    payload: payload,
  };
};

const fetchDataSubFailed = (payload) => {
  return {
    type: "CHECK_DATASUB_FAILED",
    payload: payload,
  };
};

export const fetchDataSub = (account) => {
  return async (dispatch) => {
    dispatch(fetchDataSubRequest());
    try {
      let isAlEnabled4 = await store
        .getState()
        .blockchain.smartContract.methods.isAlSaleEnabled(4)
        .call();
      let isAlEnabled5 = await store
        .getState()
        .blockchain.smartContract.methods.isAlSaleEnabled(5)
        .call();
      let isAlEnabled6 = await store
        .getState()
        .blockchain.smartContract.methods.isAlSaleEnabled(6)
        .call();
      let isAlEnabled7 = await store
        .getState()
        .blockchain.smartContract.methods.isAlSaleEnabled(7)
        .call();
      let isWlEnabled = await store
        .getState()
        .blockchain.smartContract.methods.isWlSaleEnabled(0)
        .call();
      let revealed = await store
        .getState()
        .blockchain.smartContract.methods.revealed()
        .call();

      dispatch(
        fetchDataSubSuccess({
          isAlEnabled4,
          isAlEnabled5,
          isAlEnabled6,
          isAlEnabled7,
          isWlEnabled,
          revealed,
          // cost,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataSubFailed("Could not load data from contract."));
    }
  };
};
