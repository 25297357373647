import React, { useEffect, useState } from "react";

const EachTextAnime = ({ text }) => {
  const [isAppear, setIsAppear] = useState(0);
  const [displayText, setDisplayText] = useState("");

  const appearAnim = (text) => {
    let textbox = "";
    text.split("").forEach((t, i) => {
      textbox += '<span style="animation-delay:.' + i + 's;">' + t + "</span>";
    });
    setDisplayText(textbox);
  };
  const eachTextAnimeControl = () => {
    const elements = document.querySelectorAll(".eachTextAnime");
    elements.forEach((element) => {
      const elemPos = element.getBoundingClientRect().top - 50;
      const scroll = window.scrollY;
      const windowHeight = window.innerHeight;
      if (scroll >= elemPos - windowHeight) {
        element.classList.add("appeartext");
        setIsAppear(isAppear + 1);
      } else {
        element.classList.remove("appeartext");
        setIsAppear(isAppear + 1);
      }
    });
  };

  useEffect(() => {
    appearAnim(text);
    eachTextAnimeControl();
  }, []); // 空の依存リストを渡して、コンポーネントのマウント時にのみ実行されるようにする
  useEffect(() => {
    let timeoutId = setTimeout(() => {
      setDisplayText("");
      appearAnim(text);
      eachTextAnimeControl();
    }, 2000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [isAppear]); // 空の依存リストを渡して、コンポーネントのマウント時にのみ実行されるようにする

  return (
    <div
      className="eachTextAnime"
      dangerouslySetInnerHTML={{
        __html: displayText,
      }}
    />
  );
};

export default EachTextAnime;
